import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import { addToast, convertDateISOstart } from '@flowardco/flib-util';
import {
  internalHttpClient,
  API_ENDPOINTS,
  API_SERVICE_URLS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';
import { getUserSessionId, setUserSessionId } from '@/lib/util/sessionStore';
import {
  formatDeliveryDatesResponse,
  formatDeliverySlotResponse,
} from '@flowardco/app-shared/helpers/utilities';
import { Language } from '@/lib/helpers/constants';

const orderClient = internalHttpClient({ baseURL: API_SERVICE_URLS.ORDER });
const homeClient = internalHttpClient({ baseURL: API_SERVICE_URLS.HOME });
const orderV2Client = internalHttpClient({ baseURL: API_SERVICE_URLS.ORDERV2 });
const client = internalHttpClient({ baseURL: API_SERVICE_URLS.API2 });

export const fetchUserSessionId = async (OrderToken: string) => {
  try {
    const res = await orderClient.post(
      API_ENDPOINTS.recipientApp.getSessionId,
      {
        OrderToken,
      }
    );
    const sessionInfo = formatHttpResponse(res);
    if (sessionInfo?.sessionId) {
      setUserSessionId(sessionInfo?.sessionId);
    }
    return sessionInfo?.sessionId;
  } catch (error: any) {
    errorLoggerV2('AddressPage::fetchUserSessionId', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};

export const fetchUserDeliveryData = async (
  orderToken: string,
  includeFirstSlot: boolean = false
) => {
  try {
    let sessionId = getUserSessionId();
    if (!sessionId) {
      sessionId = await fetchUserSessionId(orderToken);
    }
    const clientWithToken = internalHttpClient({
      baseURL: API_SERVICE_URLS.ORDER,
      headers: {
        Flwsession: sessionId || '',
      },
    });
    const clientV2WithToken = internalHttpClient({
      baseURL: API_SERVICE_URLS.ORDERV2,
      headers: {
        Flwsession: sessionId || '',
      },
    });
    const [orderData, firstSlot] = await Promise.allSettled([
      clientWithToken.get(
        formatUrlParams(API_ENDPOINTS.recipientApp.getUserDeliveryData, {
          orderToken,
        })
      ),
      includeFirstSlot
        ? clientV2WithToken.get(
            formatUrlParams(API_ENDPOINTS.recipientApp.getFirstSlot, {
              orderToken,
            })
          )
        : Promise.resolve({ data: [] }),
    ]);
    const orderInfo =
      orderData?.status === 'fulfilled' ? orderData?.value?.data : {};
    const firstSlotInfo =
      firstSlot?.status === 'fulfilled' ? firstSlot?.value?.data : [];
    const userDeliveryInfo = { orderInfo, firstSlotInfo };
    return userDeliveryInfo;
  } catch (error: any) {
    errorLoggerV2('AddressPage::fetchUserDeliveryData', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};

export const fetchCallCenterData = async (opsId: number, lang: string) => {
  try {
    const clientWithHeaders = internalHttpClient({
      baseURL: API_SERVICE_URLS.HOME,
      headers: {
        opsId,
        lang: lang === 'en' ? Language.en : Language.ar,
      },
    });

    const res = await clientWithHeaders.get(
      API_ENDPOINTS.recipientApp.getCustomerService
    );

    const costumerServiceInfo = formatHttpResponse(res);
    return costumerServiceInfo;
  } catch (error: any) {
    errorLoggerV2('AddressPage::fetchCallCenterData', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};

export const fetchAreasForOps = async (opsId: number) => {
  try {
    const res = await homeClient.get(API_ENDPOINTS.getAreaList, {
      headers: {
        opsId,
      },
    });
    const areaListInfo = formatHttpResponse(res);
    return areaListInfo;
  } catch (error: any) {
    errorLoggerV2('AddressPage::fetchAreasForOps', error);
    return [];
  }
};
export const fetchGeoFencedAreasForOps = async (opsId: number) => {
  try {
    const res = await client.get(API_ENDPOINTS.getGeofencedAreaList, {
      headers: {
        opsId,
      },
    });
    const areaListInfo = formatHttpResponse(res);
    return areaListInfo;
  } catch (error: any) {
    errorLoggerV2('AddressPage::fetchGeoFencedAreasForOps', error);
    return [];
  }
};
export const changeDeliveryDates = async (
  orderToken: string,
  date: string,
  deliverySlot: string | number
) => {
  try {
    let sessionId = getUserSessionId();
    if (!sessionId) {
      sessionId = await fetchUserSessionId(orderToken);
    }
    const DeliveryDateTime = convertDateISOstart(new Date(date));
    const res = await orderClient.put(
      formatUrlParams(API_ENDPOINTS.recipientApp.getAvailableDates, {
        orderToken,
      }),
      {
        DeliveryDateTime,
        deliverySlot,
      },
      {
        headers: {
          Flwsession: sessionId || '',
        },
      }
    );
    const datesResponse = formatHttpResponse(res);
    return datesResponse;
  } catch (error: any) {
    errorLoggerV2('AddressPage::changeDeliveryDates', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};

export const getDeliveryDates = async (
  orderToken: string,
  __T: any,
  __TK: any
) => {
  try {
    let sessionId = getUserSessionId();
    if (!sessionId) {
      sessionId = await fetchUserSessionId(orderToken);
    }
    const res = await orderClient.get(
      formatUrlParams(API_ENDPOINTS.recipientApp.getAvailableDates, {
        orderToken,
      }),
      {
        headers: {
          Flwsession: sessionId || '',
        },
      }
    );
    const datesList = formatHttpResponse(res);
    return formatDeliveryDatesResponse({ data: datesList || [], __T, __TK });
  } catch (error: any) {
    errorLoggerV2('AddressPage::getDeliveryDates', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};

export const getDeliverySlots = async (
  orderToken: string,
  date: string,
  opsId: string | number,
  __T: any
) => {
  try {
    let sessionId = getUserSessionId();
    if (!sessionId) {
      sessionId = await fetchUserSessionId(orderToken);
    }
    const unixDate = convertDateISOstart(new Date(date));
    const res = await orderClient.get(
      formatUrlParams(API_ENDPOINTS.recipientApp.getAvailableTimeSlots, {
        orderToken,
      }),
      {
        params: {
          date: unixDate,
          opsId,
        },
        headers: {
          Flwsession: sessionId || '',
        },
      }
    );
    const slots = formatHttpResponse(res);
    return formatDeliverySlotResponse({ data: slots || {}, __T });
  } catch (error: any) {
    errorLoggerV2('AddressPage::getDeliverySlots', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};

export const changeAddressData = async (data: any) => {
  try {
    let sessionId = getUserSessionId();
    if (!sessionId) {
      sessionId = await fetchUserSessionId(data?.token);
    }
    const res = await orderClient.post(
      API_ENDPOINTS.recipientApp.changeRecipientAddress,
      data,
      { headers: { Flwsession: sessionId || '' } }
    );
    const updatedAddress = formatHttpResponse(res);
    return updatedAddress;
  } catch (error: any) {
    errorLoggerV2('AddressPage::changeAddressData', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};

export const validateMapLatLngDeliveryArea = async ({
  lat = '',
  lng = '',
  placeName = '',
  opsId = 1,
  __T,
}: {
  lat?: string | number;
  lng?: string | number;
  placeName?: string;
  opsId?: string | number;
  __T: any;
}) => {
  try {
    const data = await orderClient.get(API_ENDPOINTS.checkLocationMap, {
      params: { lat, lng, placeName, opsId },
      headers: { opsId },
    });
    const formattedResponse = formatHttpResponse(data);
    return formattedResponse;
  } catch (error: any) {
    errorLoggerV2('AddressPage::validateMapLatLngDeliveryArea', error);
    addToast(
      __T(
        "Sorry, we don't deliver to this area. For help, contact Customer Service."
      ),
      'error'
    );
    return null;
  }
};
export const validateGeoMapLatLngDeliveryArea = async ({
  lat = '',
  lng = '',
  placeName = '',
  opsId = 1,
  __T,
  orderId = '',
}: {
  lat?: string | number;
  lng?: string | number;
  placeName?: string;
  opsId?: string | number;
  __T: any;
  orderId?: string | number;
}) => {
  try {
    const data = await client.get(API_ENDPOINTS.checkGeoLocationMap, {
      params: { lat, lng, placeName, opsId, orderId },
      headers: { opsId },
    });
    const formattedResponse = formatHttpResponse(data);
    if (!formattedResponse.isGeofenced) {
      addToast(
        __T(
          "Sorry, we don't deliver to this area. For help, contact Customer Service."
        ),
        'error'
      );
      return null;
    }
    return formattedResponse;
  } catch (error: any) {
    errorLoggerV2('AddressPage::validateMapLatLngDeliveryArea', error);
    addToast(
      __T(
        "Sorry, we don't deliver to this area. For help, contact Customer Service."
      ),
      'error'
    );
    return null;
  }
};
export const fetchFirstSlot = async (orderToken: string) => {
  try {
    let sessionId = getUserSessionId();
    if (!sessionId) {
      sessionId = await fetchUserSessionId(orderToken);
    }
    const res = await orderV2Client.get(
      formatUrlParams(API_ENDPOINTS.recipientApp.getFirstSlot, { orderToken }),
      {
        headers: {
          Flwsession: sessionId || '',
        },
      }
    );
    const firstSlotInfo = formatHttpResponse(res);
    return firstSlotInfo;
  } catch (error: any) {
    errorLoggerV2('AddressPage::fetchFirstSlot', error);
    return [];
  }
};
