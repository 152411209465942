import { Suspense, useState } from 'react';
import { Await, useLoaderData, useParams } from 'react-router-dom';
import { PageLayout } from '@/components/PageLayout';
import type { RecipientAddressModel } from '@flowardco/flib-models';
import { AddressPageSkeleton } from '@/components/AddressPageUI/AddressPageSkeleton';
import { AddressPageUI } from '@/components/AddressPageUI/AddressPageUI';
import { PageError } from '@/components/PageError';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';

const AddressPage = () => {
  const [showCallCenter, setShowCallCenter] = useState<boolean>(false);
  const { orderData } = useLoaderData() as { orderData: RecipientAddressModel };
  const { token = '', lang = 'en' } = useParams();
  const onCustomerSupportClick = () => {
    setShowCallCenter((prevState) => !prevState);
  };
  return (
    <PageLayout
      showCustomerSupport
      onCustomerSupportClick={onCustomerSupportClick}
    >
      <Suspense fallback={<AddressPageSkeleton />}>
        <Await resolve={orderData}>
          {(resolvedOrderData) => {
            if (!resolvedOrderData?.orderInfo?.id) {
              return <PageError message='Order Not Found' />;
            }
            return (
              <AppErrorBoundary>
                <AddressPageUI
                  token={token}
                  lang={lang}
                  showCallCenter={showCallCenter}
                  setShowCallCenter={setShowCallCenter}
                  orderData={resolvedOrderData?.orderInfo}
                  firstSlotInfo={resolvedOrderData?.firstSlotInfo}
                />
              </AppErrorBoundary>
            );
          }}
        </Await>
      </Suspense>
    </PageLayout>
  );
};

export default AddressPage;
