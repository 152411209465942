import type { SlotCardProps } from '@flowardco/fui-slot-card/src';
import dayJs from 'dayjs';
import type { RecipientAddressModel } from '@flowardco/flib-models';
import { OrderCStepEnum, OrderStatusEnum } from '@flowardco/flib-models';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayJs.extend(customParseFormat);

export const formatDeliverySlots = ({
  __T,
  __TK,
  orderData,
  updatedSlot,
  firstSlotInfo,
}: {
  __T: any;
  __TK: any;
  orderData: RecipientAddressModel;
  updatedSlot: { date: any; slot: any };
  firstSlotInfo: any;
}): SlotCardProps[] => {
  const isSlotExpired = orderData?.passedCutoffTime || false;
  const isNewDateSelected = updatedSlot?.date !== null;
  const deliveryDate = dayJs(orderData?.deliveryDate, 'DD/MM/YYYY');
  let firstSlotItem: SlotCardProps = {
    title: deliveryDate?.format('DD MMM'),
    isSelected: true,
    // disabled: orderData?.passedCutoffTime || false,
    titleClass: 'fui-uppercase',
    subTitle: __TK(orderData?.weekDayEn, orderData?.weekDayAr),
    content: orderData?.deliveryTime,
    id: 'currentSlot',
    className: 'md:!fui-w-36 md:!fui-p-2.5',
  };

  // new slot selected by customer
  if (isNewDateSelected) {
    const newSlotDate = dayJs(updatedSlot?.date, 'YYYY-MM-DD');
    const firstSlotDate = dayJs(firstSlotInfo?.date, 'YYYY-MM-DD');
    const isSameAsFirstDate =
      newSlotDate?.isSame(firstSlotDate, 'day') &&
      updatedSlot?.slot?.id === firstSlotInfo?.id;
    firstSlotItem = {
      ...firstSlotItem,
      title:
        isSameAsFirstDate && isSlotExpired
          ? __T('Fastest delivery')
          : newSlotDate?.format('DD MMM'),
      subTitle: updatedSlot?.slot?.title
        ? __T(updatedSlot?.slot?.title)
        : __T(newSlotDate?.format('dddd')),
      content: updatedSlot?.slot?.name,
    };
  }

  return [
    firstSlotItem,
    {
      title: __T('Another time'),
      subTitle: null,
      content: __T('Choose another date and time'),
      isSelected: false,
      disabled: isOutForDelivery(orderData) || isDetailsPrinted(orderData),
      id: 'selectAnotherSlot',
      className: 'md:!fui-w-36 md:!fui-p-2.5',
    },
  ];
};

export const isOutForDelivery = (orderData: RecipientAddressModel) => {
  return orderData?.cstep
    ? [
        OrderCStepEnum.Preparing,
        OrderCStepEnum.Delivering,
        OrderCStepEnum.Delivered,
        OrderCStepEnum.Picked,
        OrderCStepEnum.Ready,
        OrderCStepEnum.Returned,
        OrderCStepEnum.FailedDelivery,
      ].includes(orderData?.cstep)
    : false;
};

export const isOrderCancelled = (orderData: RecipientAddressModel) => {
  return orderData?.status
    ? [OrderStatusEnum.Canceled, OrderStatusEnum.Pending_Cancellation].includes(
        orderData?.status
      )
    : false;
};

export const isOrderNotConfirmed = (orderData: RecipientAddressModel) => {
  return orderData?.status || orderData?.status === 0
    ? [OrderStatusEnum.Not_Confirmed, OrderStatusEnum.PreOrderPending].includes(
        orderData?.status
      )
    : false;
};

export const isDetailsPrinted = (orderData: RecipientAddressModel) => {
  return orderData?.detailsPrinted ?? false;
};

export const isAddressEditable = (orderData: RecipientAddressModel) => {
  return (
    (!isOutForDelivery(orderData) &&
      (!orderData?.isMapPinMandatory ||
        Number(orderData?.ops?.countryID) === 8)) ||
    (orderData?.isMapPinMandatory &&
      `${orderData?.lat || ''}`?.trim() !== '' &&
      `${orderData?.lng || ''}`?.trim() !== '' &&
      `${orderData?.addressLineOne || ''}`?.trim() !== '')
  );
};
