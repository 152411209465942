import { replaceSpacesAndAmpersands } from '@flowardco/flib-util';
interface FormatPDPUrlProps {
  slug: string;
  id: string | number;
  opsUrl: string;
  pageSource?: string;
  source?: string;
  sourceId?: number;
  landingSource?: string;
  sourceValue?: string;
  sourceName?: string;
}

interface FormatPDPAlgoliaUrlProps extends FormatPDPUrlProps {
  queryId: string;
  landingSource?: string;
  sourceValue?: string;
  sourceName?: string;
  breadcrumb?: string;
}

export const formatPDPUrl = ({
  slug,
  id,
  opsUrl,
  pageSource,
  source,
  sourceId,
  landingSource,
  sourceValue,
  sourceName,
}: FormatPDPUrlProps): string => {
  const trackingData: string = `${
    landingSource
      ? `&landing_source=${replaceSpacesAndAmpersands(`${landingSource}`)}`
      : ''
  }${
    sourceValue
      ? `&source_value=${replaceSpacesAndAmpersands(`${sourceValue}`)}`
      : ''
  }${
    sourceName
      ? `&source_name=${replaceSpacesAndAmpersands(`${sourceName}`)}`
      : ''
  }`;
  const pdpUrl = `${opsUrl}buy-and-send-${encodeURIComponent(
    slug
  )}-${id}-online.html?page_source=${pageSource}${trackingData}`;

  if (source && sourceId) {
    return `${pdpUrl}&source=${encodeURIComponent(
      source
    )}&sourceId=${encodeURIComponent(sourceId)}`;
  }

  return pdpUrl;
};

export const formatPDPAlgoliaUrl = ({
  slug,
  id,
  opsUrl,
  queryId,
  pageSource,
  source,
  sourceId,
  landingSource,
  sourceValue,
  sourceName,
  breadcrumb,
}: FormatPDPAlgoliaUrlProps): string => {
  const trackingData: string = `${
    landingSource
      ? `&landing_source=${encodeURIComponent(
          replaceSpacesAndAmpersands(`${landingSource}`)
        )}`
      : ''
  }${
    sourceValue
      ? `&source_value=${replaceSpacesAndAmpersands(`${sourceValue}`)}`
      : ''
  }${
    sourceName
      ? `&source_name=${encodeURIComponent(
          replaceSpacesAndAmpersands(`${sourceName}`)
        )}`
      : ''
  }`;

  const breadcrumbParam = breadcrumb
    ? `&breadcrumb=${replaceSpacesAndAmpersands(`${breadcrumb}`)}`
    : '';
  const pdpUrl = `${opsUrl}buy-and-send-${encodeURIComponent(
    slug
  )}-${id}-online.html?queryId=${queryId}&page_source=${pageSource}${trackingData}${breadcrumbParam}`;

  if (source && sourceId) {
    return `${pdpUrl}&source=${source}&sourceId=${sourceId}`;
  }

  return pdpUrl;
};
