import type { RefObject, Dispatch, SetStateAction } from 'react';
import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import { addToast } from '@flowardco/flib-util';
import fixWebmDuration from 'fix-webm-duration';

declare global {
  interface Window {
    webkitAudioContext: any;
  }
}

// Load all images before drawing them to canvas
function loadImages(names: string[], files: string[], onAllLoaded: () => void) {
  let i = 0;
  let numImgsLoading = names.length;
  const onLoad = () => --numImgsLoading === 0 && onAllLoaded();
  const onError = (error: any) => {
    addToast('There was a problem loading the image.');
    errorLoggerV2('canvas::loadImages', error);
  };

  const images = {};
  while (i < names.length) {
    const img = (images[names[i]] = new Image());
    img.src = files[i++];
    img.onload = onLoad;
    img.onerror = onError;
  }
  return images;
}

// Main drawing to canvas function
export const drawOnCanvas = async (
  canvasRef: RefObject<HTMLCanvasElement>,
  getSelectedFrameSrc: () => string,
  userFileBlob: string,
  bgColor: string,
  drawBackground: boolean,
  isVideo: boolean
): Promise<void> => {
  return new Promise((resolve) => {
    const frameSrc = getSelectedFrameSrc();
    const imageCollection = loadImages(
      ['logo', 'frame', 'hand', ...(!isVideo ? ['userImage'] : [])],
      [
        '/assets/logo-brand.svg',
        frameSrc,
        '/assets/floward-hand.svg',
        ...(!isVideo ? [userFileBlob] : []),
      ],
      () => onAllLoaded(imageCollection)
    );

    const onAllLoaded = (imageCollection: Record<string, HTMLImageElement>) => {
      try {
        let canvas, ctx;

        if (canvasRef.current) {
          canvas = canvasRef.current;
          ctx = canvas.getContext('2d');
        }

        if (canvas && ctx) {
          const canvasWidth = 1080;
          const canvasHeight = 1080;
          const canvasAspectRatio = canvasWidth / canvasHeight;
          const gap = 80;

          // Draw the background only when user clicks continue
          if (drawBackground) {
            drawCanvasBg(canvasWidth, canvasHeight, bgColor, ctx);
          } else {
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
          }

          // Then draw the images to the canvas
          drawFrame(
            imageCollection.frame,
            canvasAspectRatio,
            canvasWidth,
            canvasHeight,
            gap,
            ctx
          );
          drawLogo(imageCollection.logo, canvasWidth, ctx);
          drawHand(imageCollection.hand, canvasWidth, canvasHeight, ctx);
          if (!isVideo) {
            drawImage(imageCollection.userImage, ctx);
          } else {
            drawVideo(userFileBlob, ctx);
          }
          resolve();
        }
      } catch (error: any) {
        addToast('There was an error editing your images.', 'error');
        errorLoggerV2('canvas::onAllLoaded', error);
      }
    };
  });
};

// Draw the Floward frame
const drawFrame = (
  image: HTMLImageElement,
  canvasRatio: number,
  canvasWidth: number,
  canvasHeight: number,
  gap: number,
  ctx: CanvasRenderingContext2D
): void => {
  if (image && ctx) {
    if (image.complete) {
      const imageAspectRatio = image.width / image.height;
      let frameWidth, frameHeight;
      if (imageAspectRatio > canvasRatio) {
        frameWidth = canvasWidth;
        frameHeight = frameWidth / imageAspectRatio;
      } else {
        frameHeight = canvasHeight - gap * 2;
        frameWidth = frameHeight * imageAspectRatio;
      }

      const x = (canvasWidth - frameWidth) / 2;
      const y = gap;

      try {
        ctx.drawImage(image, x, y, frameWidth, frameHeight);
      } catch (error: any) {
        addToast('Error drawing image to canvas.', 'error');
        errorLoggerV2('canvas::drawFrame', error);
      }
    } else {
      addToast('Frame image is not loaded', 'error');
    }
  } else {
    addToast('Image or canvas context is not defined.', 'error');
  }
};

// Draw the user uploaded image
const drawImage = (
  image: HTMLImageElement,
  ctx: CanvasRenderingContext2D
): void => {
  if (image && ctx) {
    if (image.complete) {
      const destinationX = 352;
      const destinationY = 240;
      const destinationWidth = 380;
      const destinationHeight = 497;

      const widthRatio = image.width / destinationWidth;
      const heightRatio = image.height / destinationHeight;
      const scaleFactor = Math.min(widthRatio, heightRatio);

      const sourceWidth = destinationWidth * scaleFactor;
      const sourceHeight = destinationHeight * scaleFactor;
      const sourceX = (image.width - sourceWidth) / 2;
      const sourceY = (image.height - sourceHeight) / 2;

      try {
        ctx.drawImage(
          image,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          destinationX,
          destinationY,
          destinationWidth,
          destinationHeight
        );
      } catch (error: any) {
        addToast('Error drawing image to canvas.', 'error');
        errorLoggerV2('canvas::drawImage', error);
      }
    } else {
      addToast('Uploaded image is not loaded.', 'error');
    }
  } else {
    addToast('Image or canvas context is not defined.', 'error');
  }
};

// Draw the user uploaded video
const drawVideo = (blobUrl: string, ctx: CanvasRenderingContext2D): void => {
  try {
    const video = document.createElement('video');
    video.src = blobUrl;
    video.volume = 0;

    const destinationX = 352;
    const destinationY = 240;
    const destinationWidth = 380;
    const destinationHeight = 497;

    const drawFrame = () => {
      const widthRatio = video.videoWidth / destinationWidth;
      const heightRatio = video.videoHeight / destinationHeight;
      const scaleFactor = Math.min(widthRatio, heightRatio);

      const sourceWidth = destinationWidth * scaleFactor;
      const sourceHeight = destinationHeight * scaleFactor;
      const sourceX = (video.videoWidth - sourceWidth) / 2;
      const sourceY = (video.videoHeight - sourceHeight) / 2;

      ctx.drawImage(
        video,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        destinationX,
        destinationY,
        destinationWidth,
        destinationHeight
      );

      if (!video.paused && !video.ended) {
        requestAnimationFrame(drawFrame);
      }
    };

    video.addEventListener('ended', () => {
      video.pause();
      video.remove();
    });

    video.addEventListener('play', () => {
      requestAnimationFrame(drawFrame);
    });

    video.play();
  } catch (error: any) {
    addToast('Error drawing video to canvas.', 'error');
    errorLoggerV2('canvas::drawVideo', error);
  }
};

// The scaling factor is used to increase the size of the logo and hand images.
const scalingFactor = 2.2;

// Draw Floward's logo
const drawLogo = (
  image: HTMLImageElement,
  canvasWidth: number,
  ctx: CanvasRenderingContext2D
): void => {
  if (image && ctx) {
    if (image.complete) {
      const x = (canvasWidth - image.width * scalingFactor) / 2;
      try {
        ctx.drawImage(
          image,
          x,
          30,
          image.width * scalingFactor,
          image.height * scalingFactor
        );
      } catch (error: any) {
        addToast('Error drawing image to canvas.', 'error');
        errorLoggerV2('canvas::drawLogo', error);
      }
    }
  }
};

// Draw the Floward hand
const drawHand = (
  image: HTMLImageElement,
  canvasWidth: number,
  canvasHeight: number,
  ctx: CanvasRenderingContext2D
): void => {
  if (image && ctx) {
    if (image.complete) {
      const x = (canvasWidth - image.width * scalingFactor) / 2;
      const y = canvasHeight - image.height * scalingFactor - 30;
      try {
        ctx.drawImage(
          image,
          x,
          y,
          image.width * scalingFactor,
          image.height * scalingFactor
        );
      } catch (error: any) {
        addToast('Error drawing image to canvas.', 'error');
        errorLoggerV2('canvas::drawHand', error);
      }
    }
  }
};

// Draw the canvas background color
const drawCanvasBg = (
  canvasWidth: number,
  canvasHeight: number,
  backgroundColor: string,
  ctx: CanvasRenderingContext2D
): void => {
  if (ctx) {
    try {
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    } catch (error: any) {
      errorLoggerV2('canvas::drawCanvasBg', error);
    }
  }
};

// Get message lines separated by a maximum drawing width
export const getLines = (
  ctx: CanvasRenderingContext2D,
  text: string,
  maxWidth: number
) => {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (const word of words.slice(1)) {
    const width = ctx.measureText(currentLine + ' ' + word).width;
    if (width < maxWidth) {
      currentLine += ' ' + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
};

// Draw the user entered text
export const drawText = (
  isMessageToggled: boolean,
  canvasRef: RefObject<HTMLCanvasElement>,
  message: string,
  setOriginalCanvasURL: Dispatch<SetStateAction<string>>,
  font: string = 'Gilroy',
  fontSize: number = 24
) => {
  if (!isMessageToggled) {
    const { current: canvas } = canvasRef;
    if (canvas) {
      const { width, height } = canvas;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        setOriginalCanvasURL(canvas.toDataURL('image/png'));
        ctx.font = `${fontSize}px ${font}`;
        const maxWidth = 400;
        const lines = getLines(ctx, message, maxWidth);
        const lineHeight = 40;
        const startY = height - 310;
        lines.forEach((line, index) => {
          const x = width / 2 - ctx.measureText(line).width / 2;
          const y = startY + index * lineHeight;
          ctx.fillText(line, x, y);
        });
      }
    }
  }
};

// Load the file into the canvas
export const loadFileIntoCanvas = (
  fileURL: string,
  canvasRef: RefObject<HTMLCanvasElement>,
  isVideo: boolean
) => {
  const canvas = canvasRef.current;
  if (canvas) {
    if (!isVideo) {
      // If file is an image
      const image: HTMLImageElement = new Image();
      image.src = fileURL;
      image.onload = () => {
        if (image.complete) {
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext('2d');
          if (!ctx) return;
          try {
            ctx.drawImage(image, 0, 0);
          } catch (error: any) {
            addToast('Error drawing image on canvas', 'error');
            errorLoggerV2('canvas::loadFileIntoCanvas', error);
          }
        }
      };
      image.onerror = (error: any) => {
        addToast('Error loading image', 'error');
        errorLoggerV2('canvas::loadFileIntoCanvas', error);
      };
    } else {
      // If file is a video
      const video: HTMLVideoElement = document.createElement('video');
      video.src = fileURL;
      video.onerror = (error: any) => {
        addToast('Error loading video', 'error');
        errorLoggerV2('canvas::loadFileIntoCanvas', error);
      };
      video.addEventListener('loadedmetadata', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
      });

      const playVideo = () => {
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        try {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        } catch (error: any) {
          addToast('Error drawing video on canvas', 'error');
          errorLoggerV2('canvas::loadFileIntoCanvas', error);
        }
        requestAnimationFrame(playVideo);
      };

      video.addEventListener('canplaythrough', () => {
        video.play();
        requestAnimationFrame(playVideo);
      });
    }
  }
};

// Convert the canvas into a file
export const convertCanvasToFile = (
  canvasRef: RefObject<HTMLCanvasElement>,
  fileURL: string,
  isVideo: boolean,
  setLoader: Dispatch<SetStateAction<boolean>>,
  setCountDown: Dispatch<SetStateAction<number>>,
  callback: (file: File | undefined, error: any) => void
): void => {
  try {
    const AudioContext: any =
      window?.AudioContext || window?.webkitAudioContext;
    const audioContext = new AudioContext();
    const ctx = audioContext;
    let startTime: any;

    if (canvasRef.current) {
      if (isVideo) {
        // Grab the audio from the user uploaded video
        const video = document.createElement('video');
        video.src = fileURL;
        const dest = ctx.createMediaStreamDestination();
        const sourceNode = ctx.createMediaElementSource(video);
        sourceNode.connect(dest);
        const audioTrack = dest.stream.getAudioTracks()[0];

        // Get the canvas stream for the video
        const canvasStream = canvasRef.current.captureStream();

        // Add the audio track to the canvas stream
        canvasStream.addTrack(audioTrack);

        // Record the canvas stream with audio
        const chunks: Blob[] = [];
        const recorder = new MediaRecorder(canvasStream);
        recorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        // Convert the recorder to a file
        recorder.onstop = () => {
          const duration = Date.now() - startTime;
          const blob = new Blob(chunks, { type: chunks[0].type });
          // add duration to the blob since media recorder does not add the duration to the metadata
          fixWebmDuration(blob, duration, (fixedBlob) => {
            const file = new File([fixedBlob], 'floward.mp4', {
              type: 'video/mp4',
            });

            video.remove();

            // Send the file back to be added in the file input state
            callback(file, null);
          });
        };

        video.onloadedmetadata = () => {
          const duration = video.duration * 1000;
          setCountDown(Math.floor(duration / 1000));
          setTimeout(() => {
            recorder.stop();
            setLoader(false);
          }, duration);

          try {
            video.play();
            recorder.start();
            startTime = Date.now();
            setLoader(true);
          } catch (error: any) {
            callback(undefined, error);
            return;
          }
        };
      } else {
        // If it's not a video convert the file into an image
        canvasRef.current.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'fileName.jpg', {
              type: 'image/jpeg',
            });
            callback(file, null);
          } else {
            callback(undefined, null);
          }
        }, 'image/jpeg');
      }
    } else {
      callback(undefined, null);
    }
  } catch (error: any) {
    callback(undefined, null);
    addToast('There was an error converting the video.', 'error');
    errorLoggerV2('canvas::convertCanvasToFile', error);
  }
};
