import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconUserShield = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='m21.438 12.5-3.594-1.406a.92.92 0 0 0-.719 0L13.531 12.5c-.312.125-.531.438-.531.75 0 3.5 2.125 5.906 4.125 6.688a.931.931 0 0 0 .719 0C19.437 19.313 22 17.155 22 13.25c0-.313-.219-.625-.563-.75ZM17.5 18.469v-5.907l2.969 1.157c-.188 2.719-1.907 4.219-2.969 4.75Zm-4.375.031H3.5v-.781C3.5 16.219 4.688 15 6.188 15c.468 0 1.187.5 2.812.5 1.469 0 2.188-.406 2.656-.469a7.62 7.62 0 0 1-.156-1.5c-.813.125-1.25.469-2.531.469-1.469 0-1.875-.5-2.781-.5C3.874 13.5 2 15.406 2 17.719v.781c0 .844.656 1.5 1.5 1.5h10.969c-.5-.406-.938-.906-1.344-1.5ZM9 13c2.469 0 4.5-2 4.5-4.5C13.5 6.031 11.469 4 9 4 6.5 4 4.5 6.031 4.5 8.5 4.5 11 6.5 13 9 13Zm0-7.5c1.625 0 3 1.375 3 3 0 1.656-1.375 3-3 3a3 3 0 0 1-3-3c0-1.625 1.344-3 3-3Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconUserShield);
export default ForwardRef;
