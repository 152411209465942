interface ThankYouMessageProps {
  title: string;
  description?: string;
}
export const ThankYouMessage = ({
  title,
  description,
}: ThankYouMessageProps) => {
  return (
    <div className='fui-flex fui-flex-col fui-items-center fui-pb-6'>
      <img
        src='/assets/icons/heart.svg'
        alt='heart'
        className='fui-h[46px] fui-mb-3 fui-mt-5 fui-w-[46px]'
      />
      {title ? (
        <h1 className=' fui-mb-2 fui-px-10 fui-text-center fui-text-2xl fui-font-bold'>
          {title}
        </h1>
      ) : null}
      {description ? (
        <p className='fui-px-10 fui-text-center fui-leading-5'>{description}</p>
      ) : null}
    </div>
  );
};
