import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconTrash = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M8.375 13h.75a.38.38 0 0 0 .375-.375v-6.75a.403.403 0 0 0-.375-.375h-.75A.38.38 0 0 0 8 5.875v6.75c0 .219.156.375.375.375ZM13.5 2.5h-2.594L9.844.75C9.594.312 9.094 0 8.563 0H5.405c-.531 0-1.031.313-1.281.75L3.062 2.5H.5c-.281 0-.5.25-.5.5v.5c0 .281.219.5.5.5H1v10.5c0 .844.656 1.5 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5V4h.5c.25 0 .5-.219.5-.5V3c0-.25-.25-.5-.5-.5Zm-8.156-.906c.031-.032.093-.094.156-.094H8.469c.062 0 .125.063.156.094l.531.906H4.812l.532-.906ZM11.5 14.5h-9V4h9v10.5ZM4.875 13h.75A.38.38 0 0 0 6 12.625v-6.75a.403.403 0 0 0-.375-.375h-.75a.38.38 0 0 0-.375.375v6.75c0 .219.156.375.375.375Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconTrash);
export default ForwardRef;
