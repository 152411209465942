const youTubeRegEx =
  /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=|shorts\/)([^#\\&\\?]*).*/;

export const isValidUrl = (urlString: string) => {
  const urlPattern =
    // eslint-disable-next-line no-useless-escape
    /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return urlPattern.test(urlString);
};

export const checkIfYouTubeLink = (url: string): boolean => {
  return youTubeRegEx.test(url);
};

export const getYoutubeId = (url: string): string | null => {
  const match = url.match(youTubeRegEx);
  return match && match?.[2]?.length === 11 ? match?.[2] : null;
};
