import { NoResult } from '@flowardco/fui-no-result';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { ButtonLink } from '@flowardco/fui-button';

export const PageError = ({ message }: { message: string }) => {
  const { __T } = useLocalTranslator();
  return (
    <div>
      <NoResult
        className='fui-h-full fui-flex-1 !fui-justify-between'
        icon={''}
        subText={__T("Sorry, we can't find this order")}
        button={
          <ButtonLink
            label={__T('Back to Homepage')}
            href={'https://floward.com'}
            size='md'
            className='fui-mb-40 !fui-px-8 !fui-py-3'
          />
        }
        text={__T(message || 'Something Went wrong')}
      />
    </div>
  );
};
