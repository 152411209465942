import type { ReactNode } from 'react';
import React from 'react';
import cx from 'clsx';
interface ShareFeelingsProps {
  className?: string;
  title: string;
  body: string;
  imageSrc: string;
  imageAlt?: string;
  additionalNode: ReactNode;
}

const ShareFeelings: React.FC<ShareFeelingsProps> = ({
  className = '',
  title = '',
  body = '',
  imageSrc = '',
  imageAlt = '',
  additionalNode,
}) => {
  return (
    <div
      className={cx(
        `fui-mb-5 fui-rounded-lg fui-px-4 fui-py-5 fui-shadow-card`,
        className
      )}
    >
      <div className='fui-mb-1 fui-flex'>
        <div className='fui-basis-8/12'>
          <h2 className='fui-mb-1 fui-text-xl fui-font-semibold md:fui-text-2xl'>
            {title}
          </h2>
          <p className='fui-text-sm md:fui-text-base'>{body}</p>
        </div>
        <img
          src={imageSrc}
          alt={imageAlt}
          className='fui-w-5/12 fui-self-start'
        />
      </div>
      {additionalNode}
    </div>
  );
};

export default ShareFeelings;
