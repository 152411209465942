import React from 'react';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { Modal } from '@flowardco/fui-modal';
import RacipientFeelingsCard from './RacipientFeelingsCard';

export const PreviewModal: React.FC<{
  onClose: () => void;
  previewDetails: any;
  url: string;
  mediaType: number | null;
  message: string;
}> = ({ onClose, previewDetails, url, mediaType, message }) => {
  const { __T } = useLocalTranslator();
  return (
    <Modal
      onIconClose={onClose}
      onBackdropClose={onClose}
      wrapperClass='fui-z-1000 !fui-p-0 fui-bottom-0 !fui-top-[unset] !fui-items-end !fui-justify-end'
      className='fui-min-h-2/3 !fui-mb-0 fui-w-full fui-rounded-t-lg md:fui-w-[520px]'
      containerClass='fui-mx-6 fui-text-center'
      closeBtnClass='!fui-top-0 fui-m-6 !fui-text-black-600'
    >
      <RacipientFeelingsCard
        title={
          previewDetails?.recipientName
            ? __T('{recipientname} sent you a thank you message', {
                recipientname: previewDetails?.recipientName,
              })
            : ''
        }
        url={url}
        isVideo={mediaType === 1}
        message={message}
      />
    </Modal>
  );
};

export default PreviewModal;
