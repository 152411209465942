import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { LoadingSpinner } from '@flowardco/fui-loading-spinner';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

const FullPageLoader = ({
  countDown,
  setCountDown,
}: {
  countDown: number;
  setCountDown: Dispatch<SetStateAction<number>>;
}) => {
  const { __T } = useLocalTranslator();

  useEffect(() => {
    let interval: any;

    if (countDown > 0) {
      interval = setInterval(() => {
        setCountDown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [countDown, setCountDown]);

  return (
    <div className='fui-absolute fui-left-0 fui-top-0 fui-z-1000 fui-flex fui-h-full fui-w-full fui-items-center fui-justify-center fui-bg-black-900/70'>
      <div className='fui-flex fui-flex-col fui-items-center fui-text-center fui-text-2xl'>
        <h2 className='fui-font-bold fui-text-white'>
          {__T('Please wait while we process your video..')}
          {` (${countDown})`}
        </h2>
        <LoadingSpinner iconClassName='fui-h-18 fui-w-10 fui-text-salmon-900' />
      </div>
    </div>
  );
};

export default FullPageLoader;
