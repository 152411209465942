import React, { useState, useEffect } from 'react';
import { Button } from '@flowardco/fui-button';
import MediaPlayer from './MediaPlayer/MediaPlayer';
import { useNavigate } from 'react-router-dom';
import { isValidUrl, getYoutubeId } from '@/lib/util/urlHelpers';

type ShowElementState = {
  youTube: boolean;
  videoPlayer: boolean;
  button: boolean;
};

interface HeroProps {
  messageInfo: any;
  __T: any;
}

const Hero: React.FC<HeroProps> = ({ messageInfo, __T }) => {
  const navigate = useNavigate();
  const [youTubeID, setYouTubeID] = useState<string | null>('');
  const [showElement, setShowElement] = useState<ShowElementState>({
    youTube: false,
    videoPlayer: false,
    button: false,
  });

  const handleOpenLink = () => {
    window.open(messageInfo?.senderFeelings?.feelingLink, '_blank');
  };

  const checkLinkSource = (source: string) => {
    if (isValidUrl(source)) {
      if (source.includes('floward.imgix.net')) {
        // If it is a Floward link, show HTML5 video player
        setShowElement({ ...showElement, videoPlayer: true });
      } else if (getYoutubeId(source)) {
        // if it is a YouTube link get the YouTube ID and show YouTube iFrame
        const youTubeID = getYoutubeId(source);
        setYouTubeID(youTubeID);
        setShowElement({ ...showElement, youTube: true });
      } else {
        setShowElement({ ...showElement, button: true });
      }
    } else {
      // If link is invalid, redirect to leave message page
      navigate('leavemsg');
    }
  };

  useEffect(() => {
    if (messageInfo?.senderFeelings?.hasSenderSharedFeelings) {
      checkLinkSource(messageInfo?.senderFeelings?.feelingLink);
    }
  }, [messageInfo?.senderFeelings?.feelingLink]);

  return (
    <div>
      <h1
        className='fui-text-center fui-text-2xl fui-font-bold fui-leading-7
      '
      >
        {__T('Hello {recipientname}, did you like your gift? 🌷', {
          recipientname: messageInfo?.recipientFeelings?.recipientName,
        })}
      </h1>
      <p className='fui-text-center fui-text-base fui-leading-8'>
        {__T('Check out what {sendername} sent you', {
          sendername: messageInfo?.senderFeelings?.isSenderSharingDetails
            ? messageInfo?.senderFeelings?.senderName
            : __T('the sender'),
        })}
      </p>
      {showElement?.button ? (
        <div className='fui-mt-4 fui-w-full fui-text-center'>
          <Button
            className='fui-w-[162px]'
            type='button'
            label={__T('Open Link')}
            rounded='md'
            size='2xl'
            onClick={handleOpenLink}
          />
        </div>
      ) : (
        <MediaPlayer
          videoLink={messageInfo?.senderFeelings?.feelingLink}
          youTubeID={youTubeID}
          showYouTube={showElement?.youTube}
          showVideoPlayer={showElement?.videoPlayer}
          __T={__T}
        />
      )}
    </div>
  );
};

export default Hero;
