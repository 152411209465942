import { Lottie } from 'react-lottie-lightweight';
import animation from '@flowardco/app-shared/assets/animations/giftbox.json';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

export const AddressIntro: React.FC<{ orderData: any }> = ({ orderData }) => {
  const { __T, __TK } = useLocalTranslator();
  return (
    <div className='fui-flex fui-w-full fui-flex-nowrap fui-justify-between'>
      <div className='fui-inline-flex fui-h-16 fui-w-16 fui-items-start fui-justify-start fui-p-1'>
        <Lottie
          config={{ animationData: animation, loop: true, autoplay: true }}
          style={{ width: '100%' }}
        />
      </div>
      <div className='fui-inline-flex fui-w-full fui-items-start fui-justify-start fui-bg-navy-90 fui-px-2 fui-py-4'>
        <p className='fui-text-md fui-text-black-400'>
          <span className='fui-block'>
            {__T('Hello {name}', { name: orderData?.name })}
          </span>
          <span>
            {__T(
              `Please provide your address before {weekday} {deliverydate} at {cutofftime} to receive your gift at the selected time by the sender or choose another time`,
              {
                cutofftime: orderData?.cutoffTime || '',
                weekday: __TK(orderData?.weekDayEn, orderData?.weekDayAr) || '',
                deliverydate: orderData?.deliveryDate || '',
              }
            )}
          </span>
        </p>
      </div>
    </div>
  );
};
