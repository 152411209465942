// Session storage keys
export const SESSION_KEYS = {
  SOURCE: 'source',
  SENDER_TOKEN: 'sender_token',
  SESSION_ID: 'user_session_id',
};

export const STORAGE_KEYS = {
  VISITED_ORDER: 'visited_order_',
};

// Frames
export type FrameImage = {
  id: number;
  src: string;
  selected: boolean;
};

export const frameImages: FrameImage[] = [
  {
    id: 1,
    src: '/assets/frames/frame1.svg',
    selected: true,
  },
  {
    id: 2,
    src: '/assets/frames/frame2.svg',
    selected: false,
  },
];

export const frameBgColor = {
  navy: '#00313C',
  salmon: '#E9B8A5',
};

export const orderStepsInfo = {
  Cancelled: {
    title: 'Order Cancelled',
    titleAr: 'الطلب ملغى',
    message: '',
    messageAr: '',
    img: '',
  },
  Received: {
    title: 'Gift ordered',
    titleAr: 'تم طلب هديتك',
    message: 'Your order will soon begin preparation',
    messageAr: 'يتم الآن تجيهز طلبك، ستبدو هديتك رائعة عندما تنتهي',
    img: '/assets/order-status-confirmed.jpg',
  },
  Preparing: {
    title: 'Preparing',
    titleAr: 'جاري تحضير هديتك',
    message: `Your gift is now being prepared and it's going to be wonderful`,
    messageAr: 'يعمل فريقنا على تجهيز الهدية، ستبدو رائعة عند الانتهاء منها',
    img: '/assets/order-status-preparing.jpg',
  },
  Delivering: {
    title: 'Delivering',
    titleAr: 'جاري التوصيل',
    message: 'Your order is with our driver and on the way!',
    messageAr: 'هديتك في الطريق!',
    img: '/assets/order-status-delivering.jpg',
  },
  Delivered: {
    title: 'Delivered',
    titleAr: 'تم توصيل الهدية',
    message: 'The gift has been delivered',
    messageAr: 'تم استلام الهدية',
    img: '/assets/order-status-delivered.jpg',
  },
};

export enum Language {
  en = 1,
  ar = 0,
}
