import { useContext } from 'react';
import {
  API_SERVICE_URLS,
  API_ENDPOINTS,
  formatHttpError,
  formatHttpResponse,
  externalHttp,
  formatUrlParams,
} from '@flowardco/flib-helpers';
import { formatOpsCountryListData } from '@flowardco/flib-util';
import { errorLogger } from '@flowardco/app-shared/helpers/ddClientLogs';
import type { AppContextProps } from '../context';
import {
  AppContext,
  SET_OPS_LIST,
  SET_APP_GLOBAL_DATA,
  SET_CF_IP_DATA,
} from '../context';
import { useFetch } from './useFetch';

export interface GetLoyaltyProgramsReqProps {
  sessionType: number;
  userId: number;
  currency: string;
  isArabic: boolean;
}

export interface AppHookInterface {
  appState: AppContextProps;
  appDispatch: any;
  getLoyaltyModalUrl: (props: GetLoyaltyProgramsReqProps) => Promise<any>;
  getGlobalData: (opsCOuntryId?: number) => PromiseSettledResult<any>;
  getUserCountryFromIP: () => Promise<any>;
  getCountryLists: () => Promise<any>;
  getAllOpsCountries: (
    lang?: string | null,
    countryCode?: string | null,
    city?: string | null,
    allops?: boolean,
    isUK?: boolean,
    isLocaleOnly?: boolean,
    isCountryDomain?: boolean
  ) => Promise<any>;
  pathname: string;
  hostname: string;
  config: any;
}

export const useApp = (): AppHookInterface => {
  const app = useContext(AppContext);
  const { appDispatch } = app;
  const client = useFetch(API_SERVICE_URLS.HOME);
  const api2Client = useFetch(API_SERVICE_URLS.API2);
  const externalApiClient = externalHttp({
    baseURL: '',
  });
  //Set app context data
  const setAppData = ({
    opsList,
    currentSelectedOpsCountry,
    currentCityOpsCenter,
    lang,
    opsUrl,
    isLocaleOnly = false,
    isCountryDomain = false,
  }: {
    opsList: any[];
    currentSelectedOpsCountry: any;
    currentCityOpsCenter: any;
    lang: string;
    opsUrl: string;
    isLocaleOnly: boolean;
    isCountryDomain: boolean;
  }) => {
    const isArabic = lang === 'ar' || false;
    appDispatch({
      type: SET_OPS_LIST,
      payload: {
        isAppLoading: false,
        opsList,
        currentOpsCountry: currentSelectedOpsCountry,
        currentOpsCenter: currentCityOpsCenter,
        lang,
        isArabic,
        isLocaleOnly,
        isCountryDomain,
        opsUrl,
        opsCountryId: currentSelectedOpsCountry?.id || 1,
        opsId: currentCityOpsCenter?.id || 1,
        currencyIso: currentSelectedOpsCountry?.currencyIso || 'KWD',
        currency: isArabic
          ? currentSelectedOpsCountry?.currencyAr
          : currentSelectedOpsCountry?.currency,
      },
    });
  };

  const getAllOpsCountries = async (
    lang = 'en',
    countryCode = '',
    city = '',
    allops = false,
    isUK = false,
    isLocaleOnly = false,
    isCountryDomain = false
  ) => {
    try {
      const data = await client.get(API_ENDPOINTS.allCountriesOpsList, {
        params: { allops },
      });
      const formattedResponse = formatHttpResponse(data);
      const opsList = formatOpsCountryListData(formattedResponse, lang);
      const currentSelectedOpsCountry = opsList.find(
        (country: any) =>
          country?.isoCode?.toLowerCase() === countryCode?.toLowerCase()
      );

      const defaultOpsCenter = currentSelectedOpsCountry?.opsList?.find(
        (itm: any) => itm.id === currentSelectedOpsCountry?.defaultOpsId
      );
      let currentCityOpsCenter = currentSelectedOpsCountry?.opsList?.find(
        (itm: any) => itm?.slug?.toLowerCase() === city?.toLowerCase()
      );

      const opsUrlPrefix =
        isLocaleOnly && isCountryDomain
          ? `/${lang?.toLowerCase()}/`
          : `/${lang?.toLowerCase()}-${countryCode?.toLowerCase()}/`;

      let opsUrl = `${opsUrlPrefix}`;
      if (
        isUK ||
        (!currentSelectedOpsCountry?.showCities && !currentCityOpsCenter)
      ) {
        currentCityOpsCenter = { ...defaultOpsCenter };
      }
      if (currentSelectedOpsCountry?.showCities) {
        opsUrl = `${opsUrlPrefix}${currentCityOpsCenter?.slug?.toLowerCase()}/`;
      }
      if (isUK) {
        opsUrl = '/';
      }
      setAppData({
        opsList,
        currentSelectedOpsCountry,
        currentCityOpsCenter,
        lang,
        opsUrl,
        isLocaleOnly,
        isCountryDomain,
      });
      return opsList;
    } catch (error: any) {
      errorLogger('useApp:getAllOpsCountries', error);
      return formatHttpError(error);
    }
  };

  const getGlobalData = async () => {
    try {
      const isMegaMenu = true;
      const [
        webHome,
        countryList,
        allOccasions,
        indexName,
        menus,
        paymentMethods,
      ] = await Promise.allSettled([
        client.get(API_ENDPOINTS.homeData),
        client.get(API_ENDPOINTS.countryList),
        client.get(API_ENDPOINTS.allOccasions),
        api2Client.get(
          formatUrlParams(API_ENDPOINTS.catalog.algoliaIndex, {
            opsId: app?.appState?.opsId || 1,
          })
        ),
        api2Client.get(
          isMegaMenu ? API_ENDPOINTS.newMegaMenu : API_ENDPOINTS.megaMenu
        ),
        api2Client.get(
          formatUrlParams(API_ENDPOINTS.getPaymenMethods, {
            opsId: app?.appState?.opsId || 1,
          })
        ),
      ]);
      appDispatch({
        type: SET_APP_GLOBAL_DATA,
        payload: {
          webHome: webHome.status === 'fulfilled' ? webHome?.value?.data : {},
          countryList:
            countryList.status === 'fulfilled' ? countryList.value?.data : [],
          allOccasions:
            allOccasions.status === 'fulfilled' ? allOccasions.value?.data : [],
          menus: menus.status === 'fulfilled' ? menus.value?.data : [],
          indexName:
            indexName.status === 'fulfilled'
              ? indexName.value?.data
              : 'product',
          paymentMethods:
            paymentMethods.status === 'fulfilled'
              ? paymentMethods.value?.data
              : [],
          isAlgoliaActive: true,
        },
      });
    } catch (error: any) {
      errorLogger('useApp:getGlobalData', error);
      return formatHttpError(error);
    }
  };

  const getCountryLists = async () => {
    try {
      const data = await client.get(API_ENDPOINTS.countryList);
      const formattedResponse = formatHttpResponse(data);
      appDispatch({
        type: SET_APP_GLOBAL_DATA,
        payload: {
          countryList: formattedResponse || [],
        },
      });
      return formattedResponse;
    } catch (error: any) {
      errorLogger('useApp:getCountryLists', error);
      return formatHttpError(error);
    }
  };

  const getLoyaltyModalUrl = async ({
    sessionType,
    userId,
    currency,
    isArabic,
  }: GetLoyaltyProgramsReqProps) => {
    try {
      const data = await api2Client.post(API_ENDPOINTS.getLoyaltyLink, {
        VipSessionPage: sessionType,
        currency,
        externalId: userId,
        locale: isArabic ? 'Ar' : 'en-US',
        platform: 'web',
      });
      const formattedResponse = formatHttpResponse(data);
      return formattedResponse;
    } catch (error: any) {
      errorLogger('useApp:getLoyaltyModalUrl', error);
      return formatHttpError(error);
    }
  };

  const getUserCountryFromIP = async () => {
    try {
      const data = await externalApiClient.get(API_ENDPOINTS.getCFUserCountry);
      const locationObject = data.data
        .trim()
        .split('\n')
        .reduce((acc: any, pair: string) => {
          const paired = pair.split('=');
          acc[paired[0]] = paired[1];
          return acc;
        }, {});
      appDispatch({ type: SET_CF_IP_DATA, payload: locationObject });
      return locationObject;
    } catch (error: any) {
      errorLogger('useApp:getUserCountryFromIP', error);
      return formatHttpError(error);
    }
  };

  return {
    ...app,
    getAllOpsCountries,
    getGlobalData,
    getLoyaltyModalUrl,
    getUserCountryFromIP,
    getCountryLists,
  };
};
