import { Outlet, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const LayoutPage = () => {
  const { lang = 'en' } = useParams();
  const language = lang.toLowerCase() === 'ar' ? 'ar' : 'en';
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  return (
    <>
      <Helmet>
        <html lang={language || 'en'} dir={dir} />
      </Helmet>
      <div className='fui-mx-auto fui-flex fui-flex-col fui-justify-start md:fui-max-w-[700px]'>
        <main className='fui-flex fui-flex-1 fui-flex-col fui-p-4'>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default LayoutPage;
