import { useState } from 'react';
import RateExperienceModal from '@/components/Shared/RateExperience/RateExperienceModal';
import RateExperienceCard from '@/components/Shared/RateExperience/RateExperienceCard';
import { useParams } from 'react-router-dom';
import { addRecipientRating } from '@/api/recipient.service';
import { useRouteLoaderData } from 'react-router-dom';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';

interface RateExperienceProps {
  data: any;
}

const RateExperience: React.FC<RateExperienceProps> = ({ data }) => {
  const [initialRating, setInitialRating] = useState<number>(0);
  const [showExperienceModal, setShowExperienceModal] =
    useState<boolean>(false);
  const [showRateExperience, setShowRateExperience] = useState<boolean>(
    !data?.hasRecipientRated
  );
  const params = useParams();
  const ratingReasonsData: any = useRouteLoaderData('root');

  const handleModalClose = () => {
    setShowExperienceModal(false);
  };

  const handleModalDismiss = () => {
    setTimeout(() => {
      setShowExperienceModal(false);
    }, 12000);
    setShowRateExperience(false);
  };

  const handleRating = (setRating: number) => {
    setInitialRating(setRating);
    setShowExperienceModal(true);
  };

  const handleDismiss = async () => {
    const sentData = {
      reasonsIds: [],
      otherReason: '',
      orderRating: null,
      isDismissed: true,
    };
    await addRecipientRating(data?.orderId, params?.token || '', sentData);
  };

  return (
    <div>
      {showExperienceModal && (
        <AppErrorBoundary>
          <RateExperienceModal
            initialRating={initialRating}
            onClose={handleModalClose}
            onRatingDismiss={handleModalDismiss}
            reasons={ratingReasonsData}
            orderId={data?.orderId}
          />
        </AppErrorBoundary>
      )}
      {showRateExperience && (
        <RateExperienceCard
          className='!fui-mt-0'
          onRating={handleRating}
          onDismiss={handleDismiss}
        />
      )}
    </div>
  );
};

export default RateExperience;
