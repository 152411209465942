import React, { useState } from 'react';
import StarRating from './StarRating';
import IconClose from '@flowardco/fui-icons/src/IconClose';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import cx from 'clsx';

interface RatingProps {
  onDismiss: () => void;
  onRating: (rating: number) => void;
  className?: string;
}
const RateExperienceCard: React.FC<RatingProps> = ({
  onDismiss,
  onRating,
  className,
}) => {
  const { __T } = useLocalTranslator();
  const [showContainer, setShowContainer] = useState<boolean>(true);

  const handleDismiss = () => {
    setShowContainer(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  const handleRating = (rating: number) => {
    if (onRating) {
      onRating(rating);
    }
  };

  return (
    <div
      className={cx(
        'fui-relative fui-my-4 fui-rounded-lg fui-bg-warning-200 fui-p-4 fui-pb-8 fui-text-center',
        className,
        showContainer ? 'fui-block' : 'fui-hidden'
      )}
    >
      <IconClose
        onClick={handleDismiss}
        className='fui-absolute fui-right-2 fui-top-2 fui-ml-auto fui-h-4 fui-cursor-pointer rtl:!fui-left-3 rtl:!fui-right-auto'
      />
      <h3 className='fui-mb-2 fui-font-semibold'>
        {__T('Rate your experience')}
      </h3>
      <div className='fui-flex fui-justify-center fui-gap-1'>
        <StarRating maxRating={5} handleRating={handleRating} />
      </div>
    </div>
  );
};

export default RateExperienceCard;
