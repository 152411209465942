import { useLoaderData, Navigate, useNavigate } from 'react-router-dom';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import Hero from '@/components/Shared/Hero';
import ShareFeelings from '@/components/Shared/ShareFeelings';
import { Button } from '@flowardco/fui-button';
import RateExperience from '@/components/Shared/RateExperience/RateExperience';
import { PageLayout } from '@/components/PageLayout';

const Homepage = () => {
  const messageInfo: any = useLoaderData();
  const navigate = useNavigate();
  const { __T } = useLocalTranslator();

  // If the sender did not share his feelings, the user redirect to leave message page
  if (!messageInfo?.senderFeelings?.hasSenderSharedFeelings) {
    return <Navigate to={'leavemsg'} />;
  }
  // If the user has already shared his feelings, he is redirected to the done page
  if (messageInfo?.recipientFeelings?.hasRecipientSharedFeeling) {
    return <Navigate to={'leavemsg/done'} />;
  }

  const getAdditionalNode = () => {
    return (
      <Button
        label={__T('Send thank you')}
        className='fui-w-full'
        rounded='md'
        onClick={() => {
          navigate('leavemsg');
        }}
      />
    );
  };

  return (
    <PageLayout>
      <AppErrorBoundary>
        <Hero messageInfo={messageInfo} __T={__T} />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <ShareFeelings
          title={__T('Say thank you to {sendername}', {
            sendername: messageInfo?.senderFeelings?.isSenderSharingDetails
              ? messageInfo?.senderFeelings?.senderName
              : __T('the sender'),
          })}
          body={__T(
            'Reply to the sender with a thank you message and share the memory with friends.'
          )}
          imageSrc='/assets/share-feeling.png'
          additionalNode={getAdditionalNode()}
          className='fui-mt-4'
        />
      </AppErrorBoundary>
      <RateExperience data={messageInfo} />
    </PageLayout>
  );
};

export default Homepage;
