import React from 'react';
import cx from 'clsx';

export interface DownloadAppProps {
  appstoreUrl?: string;
  playstoreUrl?: string;
  appgalleryUrl?: string;
  header?: string;
  subHeader?: string;
  overrideClass?: string | null;
  className?: string;
  imageHolderClass?: string;
}

export const DownloadApp: React.FC<DownloadAppProps> = ({
  appstoreUrl = 'https://app.adjust.com/bs5zld6',
  playstoreUrl = 'https://app.adjust.com/cr703u8',
  appgalleryUrl = 'https://appgallery5.huawei.com/#/app/C102746297',
  header,
  subHeader,
  overrideClass,
  className,
  imageHolderClass,
}) => {
  const cssClass =
    overrideClass ??
    cx(
      'fui-flex fui-flex-col-reverse fui-mt-6 md:fui-flex-row md:fui-flex-wrap',
      className
    );
  return (
    <div className={cssClass} data-testid='TestId__DownloadApp'>
      <div className='fui-w-full fui-self-center fui-p-4 md:fui-w-3/5'>
        <h3 className='fui-mb-9px fui-text-base fui-font-bold fui-text-black-200 md:fui-mb-32px md:fui-text-4xl'>
          {header}
        </h3>
        <p className='fui-mb-9px fui-text-sm fui-text-black-900 md:fui-mb-32px md:fui-text-base'>
          {subHeader}
        </p>
        <ul className='fui-inline-flex'>
          <div className='-fui-mx-1 fui-flex fui-flex-nowrap fui-justify-start fui-align-middle'>
            {playstoreUrl && (
              <a
                data-testid='TestId__DownloadAppPlayStore'
                className='fui-mx-1 fui-inline-flex fui-w-[100px] fui-items-center fui-pt-0.5 fui-align-middle md:fui-w-[120px]'
                href={playstoreUrl}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='fui-inline-block fui-w-full'
                  src='https://floward.imgix.net/web/images/icons/android-app-download.svg?auto=compress'
                  loading='lazy'
                  width='120px'
                  height='70px'
                  alt=''
                />
              </a>
            )}
            {appstoreUrl && (
              <a
                data-testid='TestId__DownloadAppIOS'
                className='fui-mx-1 fui-inline-flex fui-w-[100px] fui-items-center fui-pt-0.5 fui-align-middle md:fui-w-[120px]'
                href={appstoreUrl}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='fui-inline-block fui-w-full'
                  src='https://floward.imgix.net/web/images/icons/iphone-app-download.svg?auto=compress'
                  loading='lazy'
                  width='120px'
                  height='70px'
                  alt=''
                />
              </a>
            )}
            {appgalleryUrl && (
              <a
                data-testid='TestId__DownloadAppGalleryStore'
                className='fui-mx-1 fui-inline-flex fui-w-[100px] fui-items-center fui-pt-0.5 fui-align-middle md:fui-w-[120px]'
                href={appgalleryUrl}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='fui-inline-block fui-w-full'
                  src='https://floward.imgix.net/web/images/icons/huawei-app-downloada.svg?auto=compress'
                  loading='lazy'
                  width='120px'
                  height='70px'
                  alt=''
                />
              </a>
            )}
          </div>
        </ul>
      </div>
      <div
        className={cx(
          'fui-relative fui-w-full fui-px-4 md:fui-w-2/5',
          imageHolderClass
        )}
        data-testid='TestId__DownloadAppImageHolder'
      >
        <div className='fui-bottom-4 fui-h-[327px] md:fui-absolute'>
          <img
            loading='lazy'
            className='fui-h-full'
            src='https://floward.imgix.net/web/Files/attachment/footer-app-mockup.png?w=311&h=372&auto=format'
            height='372px'
            width='311px'
            alt='mobile-download'
          />
        </div>
      </div>
    </div>
  );
};
