import React from 'react';
import { Carousel, CarouselSlide } from '@flowardco/fui-carousel';
import { Container } from '@flowardco/fui-container';
import type { FrameImage } from '@/lib/helpers/constants';
import cx from 'clsx';
import 'keen-slider/keen-slider.min.css';

interface ImageSliderProps {
  frameImages: FrameImage[];
  setFrames: React.Dispatch<React.SetStateAction<FrameImage[]>>;
}

const ImageSlider: React.FC<ImageSliderProps> = ({
  frameImages,
  setFrames,
}) => {
  const handleImageClick = (image: FrameImage) => {
    setFrames((prevImages) =>
      prevImages.map((prevImage) =>
        prevImage.id === image.id
          ? { ...prevImage, selected: true }
          : { ...prevImage, selected: false }
      )
    );
  };

  return (
    <div>
      <Carousel
        carouselOptions={{
          breakpoints: {
            '(min-width: 976px)': {
              slides: { perView: 6 },
            },
          },
          loop: false,
          mode: 'free',
          slides: { perView: 4 },
        }}
      >
        {frameImages?.map((image) => (
          <CarouselSlide key={image.id}>
            <Container className='fui-h-[80px] fui-w-[80px]'>
              <img
                src={image.src}
                alt='frame'
                className={cx(
                  'fui-flex fui-h-full fui-w-full fui-items-center fui-justify-center fui-rounded',
                  image.selected ? 'fui-border-2' : ''
                )}
                onClick={() => handleImageClick(image)}
              />
            </Container>
          </CarouselSlide>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageSlider;
