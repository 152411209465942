import React, { useEffect, useRef, useState } from 'react';
import { SkeletonLoader } from '@flowardco/fui-skeleton-loader';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}

interface YouTubeEmbedProps {
  videoId: string | null;
  width?: number;
  height?: number;
}

interface Player {
  destroy: () => void;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoId }) => {
  const playerRef = useRef<Player | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);

    const onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('player', {
        height: '390',
        width: '640',
        videoId: videoId,
        playerVars: {
          controls: 1,
          modestbranding: 1,
          autoplay: 1,
          mute: 1,
        },
        events: {
          onReady: onPlayerReady,
        },
      });
    };

    const onPlayerReady = (event: any) => {
      setIsLoading(false);
      event.target.playVideo();
    };

    if (window.YT && typeof window.YT.Player === 'function') {
      onYouTubeIframeAPIReady();
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId]);

  return (
    <div
      id='player'
      className='fui-absolute fui-left-0 fui-top-0 fui-h-full fui-w-full'
    >
      {isLoading && <SkeletonLoader />}
    </div>
  );
};

export default YouTubeEmbed;
