import type { Dispatch, SetStateAction } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import cx from 'clsx';
import { Modal } from '@flowardco/fui-modal';
import NavigationBtns from './NavigationBtns';
import Canvas from './Canvas';
import ColorChooser from './ColorChooser';
import ImageSlider from './ImageSlider';
import { frameImages, frameBgColor } from '@/lib/helpers/constants';
import { drawOnCanvas, convertCanvasToFile } from '@/lib/util/canvas';
import type { FrameImage } from '@/lib/helpers/constants';
import FullPageLoader from '@/components/Shared/FullPageLoader';

interface FramesModalProps {
  fileURL: string;
  onClose: () => void;
  setFile: Dispatch<SetStateAction<{ message: string; file: File | null }>>;
  isVideo: boolean;
}

const FramesModal: React.FC<FramesModalProps> = ({
  onClose,
  fileURL,
  setFile,
  isVideo,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [frames, setFrames] = useState<FrameImage[]>(frameImages);
  const [isConverting, setIsConverting] = useState<boolean>(false);
  const [currentBgColor, setCurrentBgColor] = useState<string>(
    frameBgColor.navy
  );
  const [countDown, setCountDown] = useState<number>(0);

  const getSelectedFrameSrc = (): string => {
    const selectedFrame = frames.find((frame) => frame.selected === true);
    if (!selectedFrame) {
      return '';
    }
    return selectedFrame.src;
  };

  const handleDrawing = async (
    withBackground: boolean = false,
    isVideo: boolean
  ) => {
    await drawOnCanvas(
      canvasRef,
      getSelectedFrameSrc,
      fileURL,
      currentBgColor,
      withBackground,
      isVideo
    );
  };

  const handleContinue = async () => {
    await handleDrawing(true, isVideo);
    // Create the canvas file
    if (canvasRef.current) {
      convertCanvasToFile(
        canvasRef,
        fileURL,
        isVideo,
        setIsConverting,
        setCountDown,
        (file, error) => {
          if (!error && file) {
            setFile((prevState: any) => ({
              ...prevState,
              file,
            }));
            onClose();
          } else {
            setFile((prevState: any) => ({
              ...prevState,
              file: null,
            }));
            onClose();
          }
        }
      );
    }
  };

  const handleClose = () => {
    setFile((prevState) => ({
      ...prevState,
      file: null,
    }));
    onClose();
  };

  useEffect(() => {
    if (fileURL) {
      handleDrawing(false, isVideo);
    }
  }, [frames]);

  return (
    <Modal
      className={cx(
        '!fui-absolute fui-bottom-0 fui-left-0 fui-right-0 fui-top-0',
        '!fui-my-0 fui-px-4 fui-py-9 md:fui-max-w-[700px]',
        { '!fui-bg-navy-500': currentBgColor === frameBgColor.navy },
        { '!fui-bg-salmon-500': currentBgColor === frameBgColor.salmon }
      )}
      containerClass='fui-h-full'
      closeBtnClass='fui-hidden'
    >
      {isConverting && (
        <FullPageLoader countDown={countDown} setCountDown={setCountDown} />
      )}
      <NavigationBtns onClose={handleClose} onContinue={handleContinue} />
      <Canvas canvasRef={canvasRef} />
      <ColorChooser setColor={setCurrentBgColor} />
      <ImageSlider frameImages={frames} setFrames={setFrames} />
    </Modal>
  );
};

export default FramesModal;
