import React from 'react';
import { Button } from '@flowardco/fui-button';
import IconChevronLeft from '@flowardco/fui-icons/src/IconChevronLeft';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

interface NaviationBtnsProps {
  onClose: () => void;
  onContinue: () => void;
}

const NavigationBtns: React.FC<NaviationBtnsProps> = ({
  onClose,
  onContinue,
}) => {
  const { __T } = useLocalTranslator();

  return (
    <div className='fui-mb-4 fui-flex fui-justify-between'>
      <Button
        className='fui-h-10 fui-w-10 fui-border-none !fui-bg-white/30'
        onClick={onClose}
        rounded='full'
      >
        <IconChevronLeft
          height={16}
          width={16}
          className='rtl:fui-rotate-180'
        />
      </Button>
      <Button
        className='fui-h-10 fui-w-28 fui-border-none !fui-bg-white !fui-text-navy-500'
        rounded='full'
        label={__T('Continue')}
        onClick={onContinue}
      />
    </div>
  );
};

export default NavigationBtns;
