import cx from 'clsx';
import { IconHeading } from '@flowardco/fui-icon-heading';
import { Snackbar } from '@flowardco/fui-snackbar';
import IconMapMarker from '@flowardco/fui-icons/src/IconMapMarker';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { AddMapButton } from '@flowardco/fui-add-map-button';
import type { Dispatch, SetStateAction } from 'react';
import { Suspense, lazy, useEffect, useState } from 'react';
import {
  fetchAreasForOps,
  fetchGeoFencedAreasForOps,
  validateGeoMapLatLngDeliveryArea,
  validateMapLatLngDeliveryArea,
} from '@/api/location.service';
import type { GroupedListType } from '@flowardco/fui-search-select';
import type {
  RecipientAddressModel,
  AreaAPIModel,
  SubAreaAPIModel,
  GeoFencedAreaAPIModel,
  SubGeoFencedAreaAPIModel,
} from '@flowardco/flib-models';

import { AddressAreaMap } from '@flowardco/fui-address-form-with-map';
import { Modal } from '@flowardco/fui-modal';
import { APP_CONFIGS } from '@flowardco/flib-helpers';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { COUNTRY_CODES } from '@flowardco/app-shared/lib/constants';
import { FullPageLoader } from '@flowardco/app-shared/components/AppLoader';
import { useNavigate } from 'react-router-dom';
import {
  isAddressEditable,
  isOrderCancelled,
  isOutForDelivery,
  isOrderNotConfirmed,
} from '@/lib/helpers/formatAddressData';
import CallCenterDrawer from './CallCenterDrawer';
import { useMapMasterExperimentFlags } from '@flowardco/app-shared/hooks/experiments/useMapMasterExperimentFlags';
import { useLocalSegment } from '@/hooks/useLocalSegment';

const AddressFormWithArea = lazy(() => import('./AddressFormWithArea'));
const AddressFormWithPostalCode = lazy(
  () => import('./AddressFormWithPostalCode')
);

const DeliveryAddressInfo = ({
  orderData,
  token,
  lang,
  callCenter,
  formId,
  onAddressSave,
  hasMapError = false,
}: {
  orderData: RecipientAddressModel;
  token: string;
  lang: string;
  formId: string;
  callCenter: {
    showCallCenter: boolean;
    setShowCallCenter: Dispatch<SetStateAction<boolean>>;
  };
  onAddressSave: (address: any, fromMap: boolean) => void;
  hasMapError?: boolean;
}) => {
  const { __T, __TK } = useLocalTranslator();
  const navigate = useNavigate();
  const [areasList, setAreasList] = useState<GroupedListType[]>([]);
  const [showMapModal, setShowMapModal] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { getGeoLocationEnabledOps } = useMapMasterExperimentFlags();
  const enabledOpsValue = getGeoLocationEnabledOps();
  const enableGeoLocation = enabledOpsValue?.some(
    (ops: any) => ops?.opsId === orderData?.ops?.id
  );
  const { trackUnavailableZones } = useLocalSegment();

  const getAreaList = async (opsId: number) => {
    const areas = await fetchAreasForOps(opsId);
    const areaNamesList: GroupedListType[] = areas?.reduce(
      (acc: any, itm: AreaAPIModel) => {
        const label = __TK(itm?.Name, itm?.NameAR);
        const options =
          itm?.subarea?.map((subArea: SubAreaAPIModel) => ({
            label: __TK(subArea?.Name, subArea?.NameAR),
            value: subArea?.Id,
          })) || [];
        return acc.concat({ label, options });
      },
      []
    );
    setAreasList(areaNamesList);
  };
  const getGeoFencedAreaList = async (opsId: number) => {
    const areas = await fetchGeoFencedAreasForOps(opsId);
    const areaNamesList: GroupedListType[] = areas?.reduce(
      (acc: any, itm: GeoFencedAreaAPIModel) => {
        const label = __TK(itm?.name, itm?.nameAR);
        const options =
          itm?.subarea?.map((subArea: SubGeoFencedAreaAPIModel) => ({
            label: __TK(subArea?.name, subArea?.nameAR),
            value: subArea?.id,
          })) || [];
        return acc.concat({ label, options });
      },
      []
    );
    setAreasList(areaNamesList);
  };

  const handleMapOpen = () => {
    setShowMapModal(true);
  };

  const handleMapClose = () => {
    setShowMapModal(false);
  };
  const handleConfirmMapLocation = async ({
    latLang,
    address,
  }: {
    latLang?: any;
    address?: any;
  }) => {
    setIsProcessing(true);
    let addressFromMap;
    if (enableGeoLocation) {
      addressFromMap = await validateGeoMapLatLngDeliveryArea({
        lat: latLang?.lat,
        lng: latLang?.lng,
        placeName: address || '',
        opsId: orderData?.ops?.id,
        orderId: orderData?.id,
        __T,
      });
      if (!addressFromMap) {
        trackUnavailableZones('Recipient Address Error', {
          lat: latLang?.lat,
          lng: latLang?.lng,
          order_id: orderData?.id,
          page_name: 'recipient_location',
        });
      }
    } else {
      addressFromMap = await validateMapLatLngDeliveryArea({
        lat: latLang?.lat,
        lng: latLang?.lng,
        placeName: address || '',
        opsId: orderData?.ops?.id,
        __T,
      });
    }
    const payload = {
      addressLineOne: addressFromMap?.addressLineOne,
      lat: addressFromMap?.lat,
      lng: addressFromMap?.lng,
      areaId: addressFromMap?.areaId,
      token,
    };
    if (addressFromMap) {
      onAddressSave(payload, true);
      handleMapClose();
    }
    setIsProcessing(false);
  };

  useEffect(() => {
    if (orderData?.ops?.id && orderData?.ops?.countryID !== 8) {
      if (enableGeoLocation) {
        getGeoFencedAreaList(orderData?.ops?.id);
      } else {
        getAreaList(orderData?.ops?.id);
      }
      if (isOutForDelivery(orderData) || isOrderCancelled(orderData)) {
        navigate('done');
      }
      if (isOrderNotConfirmed(orderData)) {
        navigate('not-confirmed');
      }
    }
  }, [orderData?.ops?.id]);

  return (
    <div className='fui-relative fui-block fui-pb-2'>
      {callCenter.showCallCenter && (
        <CallCenterDrawer
          opsId={orderData?.ops?.id}
          lang={lang || 'en'}
          onClose={() => callCenter.setShowCallCenter(false)}
          __T={__T}
        />
      )}
      {showMapModal ? (
        <Modal onBackdropClose={handleMapClose} onIconClose={handleMapClose}>
          <AppErrorBoundary>
            {isProcessing ? <FullPageLoader /> : ''}
            <AddressAreaMap
              apiKey={APP_CONFIGS?.apiKeys?.mapApiKey}
              countryCode={COUNTRY_CODES[orderData?.ops?.countryID || 1]?.code}
              center={{
                lat: parseFloat(orderData?.lat || orderData?.ops?.lat || '0'),
                lng: parseFloat(orderData?.lng || orderData?.ops?.lng || '0'),
              }}
              lang={lang}
              countryId={orderData?.ops?.countryID}
              opsId={orderData?.ops?.id}
              currency={COUNTRY_CODES[orderData?.ops?.countryID || 1]?.currency}
              defaultToMyLocation={true}
              __T={__T}
              __TK={__TK}
              validateDropPin={false}
              handleMapSelection={handleConfirmMapLocation}
              orderId={orderData?.id}
            />
          </AppErrorBoundary>
        </Modal>
      ) : null}
      <div className='fui-block fui-pb-2'>
        <IconHeading
          title={__T('Address Information')}
          icon={<IconMapMarker />}
        />
      </div>
      <div className='fui-block fui-pb-2.5'>
        <div className='fui-block fui-pb-2.5'>
          <Snackbar>
            <p className='fui-text-sm fui-font-medium fui-text-black-100'>
              {__TK(
                <>
                  The sender has set the city to {orderData?.ops?.title}. If you
                  want to change the city, please contact
                  <span
                    className='fui-inline-block fui-cursor-pointer fui-px-1 fui-text-navy-500'
                    onClick={() => callCenter.setShowCallCenter(true)}
                  >
                    Customer Support
                  </span>
                  .
                </>,
                <>
                  قام المرسل بتحديد {orderData?.ops?.titleAr} لتوصيل هديتك.
                  تواصل مع
                  <span
                    className='fui-inline-block fui-cursor-pointer fui-px-1 fui-text-navy-500'
                    onClick={() => callCenter.setShowCallCenter(true)}
                  >
                    خدمة العملاء
                  </span>
                  إذا كنت تريد تغيير المدينة
                </>
              )}
            </p>
          </Snackbar>
        </div>
        {orderData?.ops?.countryID !== 8 && (
          <div
            className={cx(
              'fui-block',
              hasMapError
                ? 'fui-rounded-xl fui-border fui-border-error-800'
                : ''
            )}
          >
            <AddMapButton
              label={__T('Add location on the map')}
              onClick={handleMapOpen}
              areaName={__TK(orderData?.areaName, orderData?.areaNameAr)}
              changeLabel={__T('Change')}
            />
          </div>
        )}
        <Suspense fallback={<div />}>
          {isAddressEditable(orderData) ? (
            <div className='fui-block fui-pb-2 fui-pt-3'>
              {/* Show this title when Not UK and Not Mandatory Map */}
              {orderData?.ops?.countryID !== 8 &&
                !orderData?.isMapPinMandatory && (
                  <h3 className='fui-block fui-pb-2 fui-text-base fui-text-black-900'>
                    {__T('Or enter the address manually')}
                  </h3>
                )}
              {/* Show the address form */}
              {orderData?.ops?.countryID === 8 ? (
                <AddressFormWithPostalCode
                  orderData={orderData}
                  token={token}
                  onSubmit={(data) => onAddressSave(data, false)}
                  lang={lang}
                  formId={formId}
                />
              ) : (
                <AddressFormWithArea
                  areas={areasList || []}
                  orderData={orderData}
                  token={token}
                  onSubmit={(data) => onAddressSave(data, false)}
                  formId={formId}
                />
              )}
            </div>
          ) : (
            <>
              {isOutForDelivery(orderData) ? (
                <Snackbar
                  noteText={__T(
                    'Your gift is already out for delivery. To make changes please contact customer service.'
                  )}
                  type='warning'
                  ableToClose={false}
                  withIcon={false}
                />
              ) : null}
            </>
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default DeliveryAddressInfo;
