import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconStar = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width='31'
    height='29'
    viewBox='0 0 31 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M15.5 0.535745L20.1693 9.38564L20.2266 9.49436L20.3477 9.51532L30.2074 11.2213L23.2335 18.3968L23.1478 18.4849L23.1653 18.6066L24.5897 28.5108L15.6103 24.0957L15.5 24.0414L15.3897 24.0957L6.41034 28.5108L7.83466 18.6066L7.85216 18.4849L7.76649 18.3968L0.792619 11.2213L10.6522 9.51532L10.7734 9.49436L10.8307 9.38564L15.5 0.535745Z'
      fill={props?.fill || 'currentColor'}
      stroke='currentColor'
      strokeWidth='2'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconStar);
export default ForwardRef;
