import React, { useEffect, useState } from 'react';
import { StatusProgressStepperV2 } from '@flowardco/fui-status-progress-stepper';
import IconCopy from '@flowardco/fui-icons/src/IconCopy';
import { copyToClipboard } from '@flowardco/flib-util';
import { orderStepsInfo } from '@/lib/helpers/constants';
import type { StepType } from '@flowardco/fui-status-progress-stepper';
import { formatOrderStepsResponse } from '@/lib/helpers/helpers';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

interface ActiveStepType {
  title: string;
  titleAr: string;
  message: string;
  messageAr: string;
  img: string;
}

interface CustomStep extends StepType {
  finished: true;
}

interface AddressHeroProps {
  steps: CustomStep[];
  orderId: number;
}

interface StepperSteps {
  img: string;
  title: string;
}

export const AddressHero: React.FC<AddressHeroProps> = ({ steps, orderId }) => {
  const { __T, __TK } = useLocalTranslator();
  const [stepperSteps, setStepperSteps] = useState<StepperSteps[]>([
    {
      img: '',
      title: '',
    },
  ]);
  const [activeStep, setActiveStep] = useState<ActiveStepType>({
    title: '',
    titleAr: '',
    message: '',
    messageAr: '',
    img: '',
  });

  const copyOrderId = () => {
    copyToClipboard(String(orderId));
  };

  useEffect(() => {
    if (steps) {
      setStepperSteps(formatOrderStepsResponse(steps, __TK));

      const currentStep = steps?.filter((step) => step.finished === true);
      if (currentStep) {
        setActiveStep(
          orderStepsInfo?.[currentStep[currentStep.length - 1]?.title] || {}
        );
      }
    }
  }, []);

  return (
    <div className='fui-mb-6 fui-border-b fui-border-gray-500 fui-pb-6'>
      <div className=' fui-flex fui-flex-col fui-items-center fui-px-5'>
        {activeStep?.img ? (
          <img
            src={activeStep?.img}
            alt='order status'
            className='fui-mx-auto fui-h-[200px] fui-w-[200px]'
          />
        ) : (
          ''
        )}
        <h1 className='fui-mb-3 fui-text-lg fui-font-bold fui-leading-5'>
          {__TK(activeStep?.title, activeStep?.titleAr)}
        </h1>
        <p className='fui-mb-3.5 fui-text-md fui-font-medium fui-leading-4'>
          {__TK(activeStep?.message, activeStep?.messageAr)}
        </p>
        <div className='fui-mb-6 fui-flex'>
          <p className='fui-leading-4'>
            {__T('Order No:')}&nbsp;
            <span>{orderId}</span>
          </p>
          <span
            className='fui-ml-2.5 fui-cursor-pointer rtl:fui-ml-auto rtl:fui-mr-2.5'
            onClick={copyOrderId}
          >
            <IconCopy className='fui-h-4 fui-w-3 -fui-translate-y-[2px] fui-text-navy-500' />
          </span>
        </div>
      </div>
      <StatusProgressStepperV2 steps={stepperSteps || []} withImages />
    </div>
  );
};
