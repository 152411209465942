import React from 'react';
import cx from 'clsx';

import type { StatusProgressStepperProps } from './types';

export const StatusProgressStepperV2: React.FC<StatusProgressStepperProps> = ({
  listItemClass,
  steps,
  withImages = false,
  completedStepImage = 'https://floward.imgix.net/web/Files/attachment/check-637532923697519064.gif?w=33&h=33&auto=compress,format',
  titleSize = 'base',
  onClick,
}) => {
  return (
    <ul
      className={cx(
        'fui-my-1 fui-flex fui-h-20 fui-w-full fui-items-center fui-justify-between fui-py-2.5'
      )}
      data-testid='TestId__StatusProgressStepper'
    >
      {steps.map((step, index) => (
        <li
          key={`${step.title}__${index}`}
          className={cx(
            listItemClass,
            'fui-relative fui-flex fui-w-full fui-items-center fui-justify-around fui-align-middle'
          )}
          data-testid='TestId__StatusProgressStep'
        >
          <div
            className={cx(
              'fui-absolute fui-flex fui-w-fit fui-flex-col fui-items-center',
              step.disabled === true ? 'fui-pointer' : ''
            )}
          >
            {(() => {
              if (withImages)
                return (
                  <img
                    className='fui-z-10 fui-mb-6px fui-h-11 fui-w-11 fui-self-center fui-rounded-full'
                    src={step.img}
                    alt={step.title}
                    data-testid='TestId__StatusProgressStepImage'
                  />
                );
              else if (step.status === 'completed')
                return (
                  <button
                    disabled={step.disabled}
                    onClick={(e) => {
                      if (onClick) {
                        onClick(e, step);
                      }
                    }}
                  >
                    <img
                      className='fui-z-10 fui-h-40px fui-w-40px fui-self-center fui-rounded-full fui-border fui-border-gray-300'
                      src={completedStepImage}
                      alt='Complete'
                      data-testid='TestId__StatusProgressStepCompletedImage'
                    />
                  </button>
                );
              else {
                return (
                  <button
                    className={cx(
                      'fui-relative fui-z-10 fui-mb-6px fui-flex fui-h-11 fui-w-11 fui-cursor-pointer fui-items-center fui-justify-center fui-rounded-full fui-border fui-border-gray-300  fui-text-white',
                      step.status === 'active' && !withImages
                        ? 'fui-bg-emerald-400'
                        : 'fui-bg-gray-400'
                    )}
                    data-testid='TestId__StatusNumber'
                    disabled={step.disabled}
                    onClick={(e) => {
                      if (onClick) {
                        onClick(e, step);
                      }
                    }}
                  >
                    {step.number || index + 1}
                  </button>
                );
              }
            })()}
            <p
              className={cx(
                'fui-text-sm md:fui-text-md',
                step.status === 'active' && !withImages
                  ? 'fui-text-emerald-400'
                  : '',
                step.status === 'completed' && !withImages
                  ? 'fui-text-green-400'
                  : '',
                `fui-text-${titleSize}`,
                step.status === 'completed'
                  ? 'fui-text-black-900'
                  : 'fui-text-gray-850'
              )}
              data-testid='TestId__StatusProgressStepTitle'
            >
              {step.title}
            </p>
          </div>
          <span
            className={cx(
              'fui-absolute -fui-top-12px fui-z-0 fui-block fui-w-[34%] ltr:fui-left-auto ltr:fui-right-[82%] rtl:fui-left-[82%] rtl:fui-right-auto md:fui-w-[60%] md:ltr:fui-right-[70%] md:rtl:fui-left-[70%]',
              index === 0
                ? 'ltr:fui-border-none rtl:fui-border-b-2'
                : 'fui-border-b-2',
              index === 0 ? 'rtl:fui-border-none' : '',
              step.status === 'completed'
                ? 'fui-border-solid fui-border-green-300'
                : 'fui-border-solid fui-border-gray-275'
            )}
          />
        </li>
      ))}
    </ul>
  );
};
