import React from 'react';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import download from 'downloadjs';
import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import { useLocalSegment } from '@/hooks/useLocalSegment';

export const ShareMedia: React.FC<{
  media: any;
  isvideo?: boolean;
  orderId?: string | number;
}> = ({ media, orderId = 0 }) => {
  const { __T } = useLocalTranslator();
  const { trackEvents } = useLocalSegment();
  const isWebShareSupported = navigator.share !== undefined;

  const handleShare = async () => {
    const mediaBlob = await fetch(media).then((r) => r.blob());
    const shareData = {
      files: [
        new File([mediaBlob], media.split('/').pop(), {
          type: mediaBlob.type,
        }),
      ],
    };
    try {
      trackEvents('Sharing clicks', { order_id: orderId });
      await navigator.share(shareData);
    } catch (error: any) {
      errorLoggerV2('ShareMedia:handleShare', undefined, { metaData: error });
    }
  };
  const handleDownload = () => {
    trackEvents('Media download', { order_id: orderId });
    download(media);
  };

  return (
    <div className='fui-mb-4 fui-flex fui-flex-col fui-rounded-lg fui-bg-[#F9FDFF] fui-p-4 fui-text-center'>
      <div>{__T('share with your friends')}</div>
      <div className='fui-flex fui-justify-center'>
        {isWebShareSupported && (
          <div className='fui-infline-flex fui-cursor-pointer'>
            <img
              className='fui-mx-auto -fui-mt-1.5 fui-h-14'
              src='/assets/social-media-share.svg'
              height={60}
              alt='social-share'
              onClick={handleShare}
            />
            <div className='-fui-mt-2.5 fui-text-xs'>{__T('share')}</div>
          </div>
        )}
        <div className='fui-infline-flex fui-cursor-pointer'>
          <img
            className='fui-mx-auto'
            src='/assets/download.png'
            height={40}
            alt='download'
            onClick={handleDownload}
          />
          <div className='fui-text-xs'>{__T('download')}</div>
        </div>
      </div>
    </div>
  );
};

export default ShareMedia;
