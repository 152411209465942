import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { Modal } from '@flowardco/fui-modal';
import IconCalendarNew from '@flowardco/fui-icons/src/IconCalendarNew';
import IconClock from '@flowardco/fui-icons/src/IconClock';
import { IconHeading } from '@flowardco/fui-icon-heading';
import { useQuery } from 'react-query';
import { getDeliveryDates, getDeliverySlots } from '@/api/location.service';
import { useParams } from 'react-router-dom';
import type { RecipientAddressModel } from '@flowardco/flib-models';
import { useEffect, useState } from 'react';
import { DeliveryDate, DeliverySlots } from '@flowardco/fui-delivery-date';
import { AppLoader } from '@flowardco/app-shared/components/AppLoader';
import { Button } from '@flowardco/fui-button';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';

const DeliverySlotsModal = ({
  onCloseModal,
  orderData,
  onConfirmDates,
}: {
  onCloseModal: any;
  onConfirmDates: (date: any, slot: any) => void;
  orderData: RecipientAddressModel;
}) => {
  const { __T, __TK } = useLocalTranslator();
  const { token = '' } = useParams();
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedSlot, setSelectedSlot] = useState<any>({});
  const {
    isLoading: isLoadingDates,

    data: datesList,
  } = useQuery(
    ['datesList', { orderId: orderData?.id }],
    () => getDeliveryDates(token, __T, __TK),
    { cacheTime: 1, enabled: selectedDate === '' }
  );
  const {
    isLoading: isLoadingSlots,

    data: slotsList,
  } = useQuery(
    ['slotsList', { selectedDate }],
    () => getDeliverySlots(token, selectedDate, orderData?.ops?.id, __T),
    {
      cacheTime: 1,
      enabled: selectedDate !== '',
    }
  );

  const onSelectDeliveryDate = (date: any) => {
    setSelectedDate(date);
    setSelectedSlot({});
  };
  const onSelectDeliverySlot = (slot: any) => {
    setSelectedSlot(slot);
  };

  const saveDeliveryDate = async () => {
    if (token && selectedDate && selectedSlot?.id) {
      onConfirmDates(selectedDate, selectedSlot);
      onCloseModal();
    }
  };

  useEffect(() => {
    if (datesList?.length > 0 && datesList?.[0]?.webDateString) {
      setSelectedDate(datesList?.[0]?.webDateString);
    }
  }, [datesList]);

  return (
    <Modal onBackdropClose={onCloseModal} onIconClose={onCloseModal} v2={true}>
      <div className='fui-block fui-border-b fui-border-b-salmon-200'>
        <h3 className='fui-block fui-pb-5 fui-text-base fui-font-semibold fui-text-black-700'>
          {__T('Another time')}
        </h3>
      </div>
      {/* Dates */}
      <div className='fui-block fui-pt-4'>
        <IconHeading
          icon={<IconCalendarNew />}
          title={__T('Select date')}
          iconClass='!fui-w-3'
          titleClass='!fui-font-normal !fui-text-md'
        />
      </div>
      <div className='fui-overflow-hidden fui-pt-4'>
        <AppErrorBoundary>
          {isLoadingDates && <AppLoader />}

          {datesList?.length > 0 && (
            <DeliveryDate
              className='fui-mx-auto fui-mb-3 fui-justify-start'
              deliveryDates={datesList}
              __T={__T}
              onChange={onSelectDeliveryDate}
              labelClassName='fui-font-bold'
              itemClassName='!fui-border-2 !fui-rounded-lg'
            />
          )}
        </AppErrorBoundary>
      </div>
      {/* Slots */}
      <div className='fui-block fui-pt-4'>
        <IconHeading
          icon={<IconClock />}
          title={__T('Select time')}
          iconClass='!fui-w-3'
          titleClass='!fui-font-normal !fui-text-md'
        />
      </div>
      <div className='fui-overflow-hidden fui-pt-4'>
        <AppErrorBoundary>
          {isLoadingSlots && <AppLoader />}
          <DeliverySlots
            data={slotsList?.avilableSlots}
            onChange={onSelectDeliverySlot}
          />
        </AppErrorBoundary>
      </div>
      <div className='fui-mt-2 fui-block fui-border-t fui-border-salmon-200 fui-py-4'>
        <Button
          type='button'
          onClick={saveDeliveryDate}
          disabled={!selectedSlot || !selectedSlot?.id}
          label={__T('Confirm')}
          className='fui-w-full !fui-rounded fui-text-sm fui-font-bold !fui-uppercase'
        />
      </div>
    </Modal>
  );
};

export default DeliverySlotsModal;
