import type { MutableRefObject } from 'react';
import React from 'react';

interface CanvasProps {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
}

const Canvas: React.FC<CanvasProps> = ({ canvasRef }) => {
  return (
    <div className='fui-h-2/3'>
      <canvas
        className='fui-mx-auto fui-h-full fui-w-full fui-object-contain md:fui-w-auto md:fui-object-cover'
        id='frameCanvas'
        width={1080}
        height={1080}
        ref={canvasRef}
      ></canvas>
    </div>
  );
};

export default Canvas;
