import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconCopy = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M13.531 2.063 11.937.468C11.658.188 11.281 0 10.876 0H5.5A1.5 1.5 0 0 0 4 1.5V3H1.5A1.5 1.5 0 0 0 0 4.5v10c0 .844.656 1.5 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5V13h2.5a1.5 1.5 0 0 0 1.5-1.5V3.125c0-.406-.188-.781-.469-1.063ZM8.312 14.5H1.689c-.125 0-.188-.063-.188-.188V4.688c0-.094.063-.188.188-.188H4v7c0 .844.656 1.5 1.5 1.5h3v1.313c0 .124-.094.187-.188.187Zm4-3H5.689c-.125 0-.188-.063-.188-.188V1.688c0-.094.063-.188.188-.188H9v2.75c0 .438.313.75.75.75h2.75v6.313c0 .124-.094.187-.188.187Zm.188-8h-2v-2h.281c.063 0 .094.031.125.063l1.531 1.53c.032.032.063.063.063.126V3.5Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconCopy);
export default ForwardRef;
