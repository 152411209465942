import type { Reasons } from './RateExperienceModal';
import cx from 'clsx';
import IconOk from '@flowardco/fui-icons/src/IconOk';
interface ReasonsTagListProps {
  reasons: Reasons[];
  selectedReasons: number[];
  handleReasonClick: (reasonId: number) => void;
  __TK: any;
}

const ReasonsTagList: React.FC<ReasonsTagListProps> = ({
  reasons,
  selectedReasons,
  handleReasonClick,
  __TK,
}) => {
  return (
    <div className='fui-flex fui-flex-col fui-justify-start'>
      {reasons?.map((reason) => {
        const isSelected = selectedReasons.includes(reason?.id);
        return (
          <label
            key={reason?.id}
            className={cx(
              'fui-relative fui-mb-2.5 fui-flex fui-select-none fui-items-center fui-justify-start fui-rounded-lg fui-bg-navy-80 fui-p-3 hover:fui-cursor-pointer',
              isSelected ? 'fui-text-navy-500' : 'hover:fui-text-navy-500'
            )}
          >
            <input
              type='checkbox'
              checked={isSelected}
              name='reasons'
              id={`reasons-${reason?.id}`}
              onChange={() => handleReasonClick(reason?.id)}
              className='fui-peer fui-invisible fui-absolute fui-opacity-0'
            />
            <span className='fui-inline-flex fui-h-5 fui-w-5 fui-items-center fui-justify-center fui-border fui-border-navy-500 fui-align-middle fui-text-transparent peer-checked:fui-bg-navy-500 peer-checked:fui-text-white'>
              <IconOk className='fui-h-4 fui-w-4' />
            </span>
            <span className='fui-inline-flex fui-px-5 fui-text-base'>
              {__TK(reason?.reasonEn, reason?.reasonAr)}
            </span>
          </label>
        );
      })}
    </div>
  );
};

export default ReasonsTagList;
