import { SlotCardList } from '@flowardco/fui-slot-card';
import IconTruck from '@flowardco/fui-icons/src/IconTruck';
import type { SlotCardProps } from '@flowardco/fui-slot-card';
import { formatDeliverySlots } from '@/lib/helpers/formatAddressData';
import type { RecipientAddressModel } from '@flowardco/flib-models';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { IconHeading } from '@flowardco/fui-icon-heading';
import { useState } from 'react';
import DeliverySlotsModal from './DeliverySlotsModal';
import { Snackbar } from '@flowardco/fui-snackbar';

const DeliverySlotsInfo = ({
  orderData,
  onConfirmDates,
  updatedSlot,
  firstSlotInfo = {},
}: {
  orderData: RecipientAddressModel;
  onConfirmDates: (date: any, slot: any) => void;
  updatedSlot: { date: any; slot: any };
  firstSlotInfo?: any;
}) => {
  const { __T, __TK } = useLocalTranslator();
  const [showSlotsModal, setShowSlotsModal] = useState<boolean>(false);
  const onCloseModal = () => setShowSlotsModal(false);
  const handleSlotClick = (e: any, itm: SlotCardProps) => {
    e.preventDefault();
    if (itm.id === 'selectAnotherSlot') {
      setShowSlotsModal(true);
    }
  };
  const slotsList = formatDeliverySlots({
    __T,
    __TK,
    orderData,
    updatedSlot,
    firstSlotInfo,
  });
  return (
    <div className='fui-block fui-pb-2.5'>
      <div className='fui-block fui-pb-2'>
        <IconHeading title={__T('Delivery time')} icon={<IconTruck />} />
      </div>
      {orderData?.passedCutoffTime ? (
        <Snackbar className='fui-mb-3 fui-mt-2'>
          <p className='fui-text-sm fui-font-medium fui-text-black-100'>
            {__TK(
              <>
                Unfortunately, the sender&apos;s original timeslot
                <span className='fui-inline fui-px-1 fui-font-bold'>
                  [{orderData?.deliveryDate}, {orderData?.deliveryTime}]
                </span>
                is no longer available. We&apos;ve scheduled it for the earliest
                available timeslot.
                <span className='fui-inline fui-pl-0.5 fui-font-bold'>
                  Feel free to choose another suitable one.
                </span>
              </>,
              <>
                عذراً، وقت التوصيل الأصلي المحدد من قبل المرسل لم يعد متوفر
                <span className='fui-inline fui-px-1 fui-font-bold'>
                  [{orderData?.deliveryDate}, {orderData?.deliveryTime}]
                </span>
                .اخترنا أسرع وقت متاح للتوصيل.
                <span className='fui-block fui-font-bold fui-text-black-900'>
                  إذا لم يكن هذا مناسباً، يمكنك تحديد توقيت آخر.
                </span>
              </>
            )}
          </p>
        </Snackbar>
      ) : null}
      <div className='fui-block fui-pb-2'>
        <SlotCardList onClick={handleSlotClick} data={slotsList} />
      </div>
      {showSlotsModal ? (
        <DeliverySlotsModal
          onCloseModal={onCloseModal}
          orderData={orderData}
          onConfirmDates={onConfirmDates}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default DeliverySlotsInfo;
