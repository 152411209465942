import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  redirect,
  defer,
} from 'react-router-dom';
import { RouteError } from '@flowardco/app-shared/components/RouteError';
import RootLayoutPage from '@/pages/Root';
import LayoutPage from '@/pages/Root/Layout';
import Homepage from '@/pages/Homepage';
import RecipientMessage from '@/pages/RecipientMessage';
import RecipientMessageCompleted from '@/pages/RecipientMessage/Completed';
import AddressPage from '@/pages/AddressPage';
import AddressDonePage from '@/pages/AddressPage/Completed';
import AddressNotConfirmedPage from '@/pages/AddressPage/NotConfirmed';
import {
  fetchRatingReasons,
  fetchRecipientMessageData,
} from '@/api/recipient.service';
import { fetchUserDeliveryData } from '@/api/location.service';

export const routesConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayoutPage />}>
      <Route
        id='root'
        path=':lang/:token'
        element={<LayoutPage />}
        loader={({ request }) => {
          // do not fetch reasons on address page
          if (request.url.includes('address')) {
            return [];
          }
          return fetchRatingReasons(2);
        }}
        errorElement={<RouteError />}
        shouldRevalidate={() => false}
      >
        <Route
          id='home'
          path=''
          index
          element={<Homepage />}
          loader={({ request, params }) => {
            return fetchRecipientMessageData(params.token!, request);
          }}
          errorElement={<RouteError />}
        />
        <Route
          path='leavemsg'
          element={<RecipientMessage />}
          loader={({ request, params }) => {
            return fetchRecipientMessageData(params.token!, request);
          }}
          errorElement={<RouteError />}
        />
        <Route
          path='leavemsg/done'
          element={<RecipientMessageCompleted />}
          loader={({ request, params }) => {
            return fetchRecipientMessageData(params.token!, request);
          }}
          errorElement={<RouteError />}
        />
        <Route
          path='address'
          element={<AddressPage />}
          loader={({ params }) => {
            return defer({
              orderData: fetchUserDeliveryData(params.token!, true),
            });
          }}
          errorElement={<RouteError />}
        />
        <Route
          path='address/done'
          element={<AddressDonePage />}
          loader={({ params }) => {
            return defer({ orderData: fetchUserDeliveryData(params.token!) });
          }}
          errorElement={<RouteError />}
        />
        <Route
          path='address/not-confirmed'
          element={<AddressNotConfirmedPage />}
          loader={({ params }) => {
            return defer({ orderData: fetchUserDeliveryData(params.token!) });
          }}
          errorElement={<RouteError />}
        />
        {/* Redirect all non matching request to website */}
        <Route path='*' loader={() => redirect('https://floward.com')} />
      </Route>
      {/* Redirect all non matching request to website */}
      <Route path='' index loader={() => redirect('https://floward.com')} />
      <Route path='*' loader={() => redirect('https://floward.com')} />
    </Route>
  )
);
