import type { Dispatch, SetStateAction } from 'react';
import React, { useRef } from 'react';
import { TextareaField } from '@flowardco/fui-input-field';
import IconUserShield from '@flowardco/fui-icons/src/IconUserShield';
import IconTrashNew from '@flowardco/fui-icons/src/IconTrashNew';
import ShareFeelings from '@/components/Shared/ShareFeelings';
import MediaView from './MediaView';

export const UploadFeelings: React.FC<{
  formInputs: any;
  mediaType: null | number;
  __T: any;
  setFormInputs: any;
  handleFileUpload: any;
  handleFormSubmit: any;
  mediaValidationMsg: boolean;
  setFile: Dispatch<SetStateAction<{ message: string; file: File | null }>>;
}> = ({
  formInputs,
  mediaType,
  setFormInputs,
  handleFileUpload,
  __T,
  handleFormSubmit,
  mediaValidationMsg,
  setFile,
}) => {
  const fileRef = useRef<any>();
  const uploadMediaNode = (
    <>
      <label
        htmlFor='upload-file'
        className='fui-my-3 fui-flex fui-w-full fui-cursor-pointer fui-items-center fui-justify-start fui-rounded fui-border fui-border-navy-450 fui-p-3 fui-align-middle'
      >
        <span className='fui-inline-flex fui-items-center fui-align-middle'>
          <img
            src='/assets/upload-plus-icons.png'
            width={32}
            height={32}
            className='fui-inline-block'
            alt=''
          />
        </span>
        <span className='fui-inline-flex fui-items-center fui-align-middle'>
          <input
            type='file'
            ref={fileRef}
            onChange={handleFileUpload}
            accept={'.jpg,.jpeg,.png,.mp4'}
            className='fui-hidden'
            id='upload-file'
          />
          {__T('Add a video or photo')}
        </span>
      </label>
      {mediaValidationMsg ? (
        <p className='fui-mx-4 fui-text-xs fui-text-error-700'>
          {__T(
            'Your file size is too large. Please make sure that your file is not larger than 20MB'
          )}
        </p>
      ) : null}
    </>
  );
  return (
    <>
      <form
        method='post'
        onSubmit={handleFormSubmit}
        id='recipientMessageForm'
        name='recipientMessageForm'
      >
        <TextareaField
          name='message'
          placeholder={__T('Start typing your message here...😍🥰')}
          onChange={(e) =>
            setFormInputs({ ...formInputs, message: e.target.value })
          }
          textAreaClassName='!fui-rounded-md'
          maxLength={120}
        />
        {formInputs.file ? (
          <div className='fui-mb-5 fui-h-auto fui-rounded-xl fui-bg-[#F9FDFF] fui-p-4'>
            <div className='fui-mb-4 fui-flex fui-items-center fui-justify-between'>
              <p className='fui-black-100 fui-px-2 fui-text-sm fui-font-medium'>
                {__T('This is how the message looks to the sender')}
              </p>
              <IconTrashNew
                width={14}
                height={16}
                className='fui-cursor-pointer fui-text-error-700'
                onClick={() => setFormInputs({ ...formInputs, file: null })}
              />
            </div>
            <MediaView
              url={URL?.createObjectURL(formInputs.file)}
              isVideo={mediaType === 1}
              message={formInputs.message}
              setFile={setFile}
              page='LeaveMessage'
            />
          </div>
        ) : (
          <ShareFeelings
            title={__T('Make it special with a photo or video.')}
            body={__T(
              'Create a memory with our frames to share with your sender and friends.'
            )}
            className='fui-mb-5 fui-mt-3 fui-bg-[#F9FDFF] !fui-shadow-none'
            imageSrc='/assets/camera-view.png'
            additionalNode={uploadMediaNode}
          />
        )}
        <div className='fui-my-4 fui-flex fui-w-full fui-items-center fui-rounded-xl fui-bg-warning-200 fui-p-4 fui-align-middle '>
          <span className='fui-inline-flex fui-w-7'>
            <IconUserShield />
          </span>
          <span className='fui-inline-flex fui-w-full fui-px-2 fui-text-md'>
            {__T(
              'The content of your message text, videos and photos are private'
            )}
          </span>
        </div>
      </form>
    </>
  );
};
