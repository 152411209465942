import { SESSION_KEYS } from '@/lib/helpers/constants';
import { sessionStore } from '@flowardco/flib-helpers';

export const setUrlSource = (source: string): void => {
  sessionStore.set(SESSION_KEYS.SOURCE, source, false);
};

export const getUrlSource = (): string | null => {
  return sessionStore.get(SESSION_KEYS.SOURCE, false) || null;
};

export const clearSessionStorage = (): void => {
  sessionStore.del(SESSION_KEYS.SOURCE);
};

export const setUserSessionId = (sessionId: string): void => {
  sessionStore.set(SESSION_KEYS.SESSION_ID, sessionId, false);
};

export const getUserSessionId = (): string | null => {
  return sessionStore.get(SESSION_KEYS.SESSION_ID, false) || null;
};
