import React, { useState } from 'react';
import IconStar from '@flowardco/fui-icons/src/IconStar';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

enum RatingStars {
  Terrible = 1,
  Bad = 2,
  OK = 3,
  Good = 4,
  Amazing = 5,
}

interface StarRatingProps {
  maxRating: number;
  handleRating: (rating: number) => void;
  initialRating?: number;
  showSelectedTitle?: boolean;
}

const StarRating: React.FC<StarRatingProps> = ({
  maxRating,
  handleRating,
  initialRating = 0,
  showSelectedTitle = false,
}) => {
  const [rating, setRating] = useState(initialRating);
  const { __T } = useLocalTranslator();

  const handleRatingClick = (clickedRating: number) => {
    setRating(clickedRating);
    if (handleRating) {
      handleRating(clickedRating);
    }
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= maxRating; i++) {
      stars.push(
        <label
          className='fui-flex fui-w-1/5 fui-cursor-pointer fui-flex-col fui-items-center fui-justify-center fui-px-2'
          key={`stars-${i}`}
          onClick={() => handleRatingClick(i)}
        >
          <IconStar
            className={`${
              i <= rating ? 'fui-text-navy-500' : 'fui-text-gray-400'
            }`}
            fill={i <= rating ? '#006782' : '#ffffff'}
          />
          <span className='fui-pt-4 fui-text-sm fui-font-semibold fui-text-black-100'>
            {__T(RatingStars[i])}
          </span>
        </label>
      );
    }
    return stars;
  };

  return (
    <div className='fui-block fui-text-center'>
      <div className='fui-flex fui-items-start fui-justify-start fui-gap-2 fui-pt-3 fui-align-top'>
        {renderStars()}
      </div>
      {showSelectedTitle && RatingStars?.[rating] ? (
        <h3 className='fui-block fui-py-3 fui-text-center fui-text-xl fui-font-bold fui-text-navy-500'>
          <span className='fui-inline-block'>{__T(RatingStars?.[rating])}</span>
          <span className='fui-inline-block fui-px-0.5'>!</span>
        </h3>
      ) : (
        ''
      )}
    </div>
  );
};

export default StarRating;
