import React from 'react';
import { ButtonLink } from '@flowardco/fui-button';
import YouTubeEmbed from './YouTubeEmbed';
import VideoPlayer from './VideoPlayer';
import IconDownload from '@flowardco/fui-icons/src/IconDownload';

interface MediaPlayerProps {
  videoLink?: string;
  youTubeID?: string | null;
  showYouTube?: boolean;
  showVideoPlayer?: boolean;
  __T: any;
}

const MediaPlayer: React.FC<MediaPlayerProps> = ({
  videoLink = '',
  youTubeID = '',
  showYouTube = false,
  showVideoPlayer = false,
  __T,
}) => {
  return (
    <>
      <div className='fui-relative fui-mt-4 fui-h-[343px] fui-w-full'>
        {showYouTube ? <YouTubeEmbed videoId={youTubeID} /> : null}
        {showVideoPlayer ? <VideoPlayer link={videoLink} __T={__T} /> : null}
      </div>
      {showVideoPlayer ? (
        <div className='fui-mt-4 fui-text-center'>
          <ButtonLink
            buttonStyle='ternary'
            rounded='full'
            className='fui-mx-auto fui-flex fui-h-[40px] fui-w-[40px] fui-justify-center fui-drop-shadow-lg'
            download
            target='_blank'
            href={videoLink}
          >
            <IconDownload className='fui-block fui-w-full fui-text-navy-575' />
          </ButtonLink>
          <p className='fui-mt-2.5 fui-text-center fui-text-xs fui-capitalize'>
            {__T('download video')}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default MediaPlayer;
