import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconColorBucket = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <g clipPath='url(#IconColorBucket_svg__a)'>
      <path d='M24 0H0v24h24V0Z' fill='currentColor' fillOpacity={0.01} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5 18.5a2 2 0 0 0 2-2c0-.736-.667-1.736-2-3-1.333 1.264-2 2.264-2 3a2 2 0 0 0 2 2Z'
        fill='currentColor'
      />
      <path
        d='m10.427 2.752 1.768 1.768'
        stroke='currentColor'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <path
        d='m11.84 4.167-7.777 7.778 5.656 5.657 7.778-7.779-5.656-5.657Z'
        stroke='currentColor'
        strokeWidth={2}
        strokeLinejoin='round'
      />
      <path
        d='m6 10.037 8.48 2.788M2 21.5h20'
        stroke='currentColor'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='IconColorBucket_svg__a'>
        <path fill='currentColor' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgIconColorBucket);
export default ForwardRef;
