import React from 'react';
import cx from 'clsx';

export interface SlotCardProps {
  onClick?(event: React.MouseEvent): void;
  className?: string;
  children?: React.ReactNode;
  isSelected?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  content?: React.ReactNode;
  titleClass?: string;
  subTitleClass?: string;
  contentClass?: string;
  disabled?: boolean;
  testId?: string;
  id?: string;
  price?: string;
}

export const SlotCard: React.FC<SlotCardProps> = ({
  children = null,
  className = '',
  isSelected = false,
  onClick,
  title,
  subTitle,
  content,
  titleClass,
  subTitleClass,
  contentClass,
  disabled = false,
  testId = 'TestId__SlotCard',
  price,
}) => {
  return (
    <button
      type='button'
      className={cx(
        'fui-flex fui-w-36 fui-min-w-[9rem] fui-appearance-none fui-flex-col fui-justify-start fui-rounded-md fui-border-2 fui-p-2.5 fui-outline-none ltr:fui-text-left rtl:fui-text-right md:fui-w-[33%] md:fui-p-[15px]',
        isSelected
          ? 'fui-cursor-default fui-border-navy-500'
          : 'fui-border-gray-550',
        disabled
          ? 'fui-cursor-not-allowed !fui-border-gray-100 fui-bg-gray-100'
          : 'fui-bg-white',
        className
      )}
      data-testid={testId}
      onClick={onClick}
      disabled={disabled}
    >
      {children || (
        <>
          {title ? (
            <h3
              className={cx(
                'fui-mb-3 fui-block fui-text-md fui-font-bold md:fui-mb-[15px]',
                isSelected ? 'fui-text-navy-500' : 'fui-text-black-900',
                disabled ? '!fui-text-gray-450' : '',
                titleClass
              )}
              data-testid='TestId__SlotCardTitle'
            >
              {title}
            </h3>
          ) : (
            ''
          )}
          {subTitle ? (
            <h3
              className={cx(
                'fui-dir-ltr fui-mb-1 fui-block fui-text-md fui-font-bold fui-text-black-900',
                disabled ? '!fui-text-gray-450' : '',
                subTitleClass
              )}
              data-testid='TestId__SlotCardSubTitle'
            >
              {subTitle}
            </h3>
          ) : (
            ''
          )}
          {content ? (
            <div
              className={cx(
                'fui-dir-ltr fui-block fui-text-md fui-font-medium fui-text-black-100 md:fui-mb-1',
                disabled ? '!fui-text-gray-450' : '',
                contentClass
              )}
              data-testid='TestId__SlotCardContent'
            >
              {content}
            </div>
          ) : (
            ''
          )}
          {price ? (
            <div
              className={cx(
                'fui-dir-ltr fui-flex fui-space-x-1 fui-text-md fui-font-medium fui-text-black-100 rtl:fui-flex-row-reverse',
                disabled ? '!fui-text-gray-450' : '',
                contentClass
              )}
              data-testid='TestId__SlotCardPrice'
            >
              <span>+</span> <span>{price}</span>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </button>
  );
};
