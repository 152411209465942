import React, { Fragment } from 'react';
import { fetchCallCenterData } from '@/api/location.service';
import { Modal } from '@flowardco/fui-modal';
import IconWhatsapp from '@flowardco/fui-icons/src/IconWhatsapp';
import IconTelephone from '@flowardco/fui-icons/src/IconTelephone';
import { useQuery } from 'react-query';
import { AppLoader } from '@flowardco/app-shared/components/AppLoader';

type CallCenterDataItem = {
  Value: number;
  Id: number;
  Title: string;
};

const CallCenterDrawer = ({
  opsId,
  lang,
  onClose,
  __T,
}: {
  opsId: number;
  lang: string;
  onClose: () => void;
  __T: any;
}) => {
  const callCenterIcons = {
    1: IconTelephone,
    2: IconWhatsapp,
  };
  const { isLoading: isLoadingCCData, data: callCenterData } = useQuery(
    ['callCenterData'],
    () => fetchCallCenterData(opsId, lang),
    {
      cacheTime: 1,
    }
  );

  return (
    <Modal
      wrapperClass='fui-z-1000 !fui-p-0 fui-bottom-0 !fui-top-[unset] !fui-items-end !fui-justify-end'
      className='fui-min-h-2/3 !fui-mb-0 fui-w-full fui-rounded-t-lg fui-pt-4 md:fui-w-[520px]'
      containerClass='fui-mx-6 fui-text-center fui-mx-auto'
      closeBtnClass='fui-hidden'
      onBackdropClose={onClose}
    >
      <div className='fui-px-4 fui-text-start'>
        <h3 className='fui-mb-2.5 fui-text-xl fui-font-medium fui-text-navy-500'>
          {__T('We are happy to help you!')}
        </h3>
        <p className='fui-mb-3 fui-font-medium'>
          {__T('Need help with something?')}
        </p>
      </div>
      {isLoadingCCData && <AppLoader />}
      <div className='fui-p-4 '>
        {callCenterData?.map((item: CallCenterDataItem) => {
          const Icon = callCenterIcons?.[item?.Id] || Fragment;
          return (
            <a
              href={`tel:${item?.Value}`}
              className='fui-mb-4 fui-flex fui-items-center'
              key={item?.Id}
            >
              <Icon className='fui-h-6 fui-w-6 fui-text-navy-500' />
              <p className='fui-ml-3 fui-text-base fui-font-medium rtl:fui-ml-auto rtl:fui-mr-3'>
                {item?.Title}
              </p>
            </a>
          );
        })}
      </div>
    </Modal>
  );
};

export default CallCenterDrawer;
