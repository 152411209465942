import IconMapMarker from '@flowardco/fui-icons/src/IconMapMarker';
import IconCalendarNew from '@flowardco/fui-icons/src/IconCalendarNew';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

export const AddressDetails = ({ data }: { data: any }) => {
  const { __T, __TK } = useLocalTranslator();
  return (
    <div>
      <div className='fui-mb-6'>
        <IconCalendarNew className='fui-mr-2.5 fui-inline-block fui-h-5 fui-w-4' />
        <h2 className='fui-mb-4 fui-mr-4 fui-inline-block fui-font-bold'>
          {__T('Delivery Time')}
        </h2>
        <p className='fui-rounded-md fui-border fui-border-gray-500 fui-p-4 fui-text-sm'>
          {data?.deliveryDate}, {data?.deliveryTime}
        </p>
      </div>
      <div className='fui-mb-4'>
        <IconMapMarker className='fui-mr-2.5 fui-inline-block fui-h-5 fui-w-4' />
        <h2 className='fui-mr-4 fui-inline-block fui-font-bold'>
          {__T('Deliver to')}
        </h2>
        <img
          src={data?.ops?.iconFullPath}
          className='fui-mr-2.5 fui-inline-block fui-h-6 fui-w-6 fui-rounded-md rtl:fui-ml-2.5'
          alt=''
        />
        <span className='fui-font-medium'>
          {__TK(data?.ops?.title, data?.ops?.titleAr)}
        </span>
      </div>
      <div className='fui-rounded-md fui-border fui-border-gray-500 fui-p-4 fui-text-sm'>
        <h3 className='fui-text-base fui-font-medium'>
          {__TK(data?.areaName, data?.areaNameAr)}
        </h3>
        <p className='fui-mb-2.5 fui-text-black-100'>{data?.addressLineOne}</p>
        <p>
          <span className='fui-inline-block fui-w-28 fui-text-sm fui-font-medium'>
            {__T('Recipient Name')}
          </span>
          :
          <span className='fui-ml-2.5 fui-text-black-100 rtl:fui-ml-auto rtl:fui-mr-2.5'>
            {data?.name}
          </span>
        </p>
        <p>
          <span className='fui-inline-block fui-w-28 fui-font-medium'>
            {__T('Mobile')}
          </span>
          :
          <span className='fui-ml-2.5 fui-text-black-100 rtl:fui-ml-auto rtl:fui-mr-2.5'>
            {data?.phoneNumber}
          </span>
        </p>
      </div>
    </div>
  );
};
