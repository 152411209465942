import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import { Button } from '@flowardco/fui-button';
import IconColorBucket from '@flowardco/fui-icons/src/IconColorBucket';
import cx from 'clsx';

interface ColorChooserProps {
  setColor: Dispatch<SetStateAction<string>>;
}

const ColorChooser: React.FC<ColorChooserProps> = ({ setColor }) => {
  const [clickedButton, setClickedButton] = useState<string>('navy');

  const handleClick = (buttonId: string): void => {
    setClickedButton(buttonId);
  };

  return (
    <div className='fui-flex fui-justify-end'>
      <div className='fui-mb-6 fui-flex fui-w-[120px] fui-items-center fui-justify-between fui-rounded-full  fui-bg-white/50 fui-px-2 fui-py-1'>
        <IconColorBucket className='fui-text-white' width={24} height={24} />
        <div className='fui-flex fui-w-16 fui-justify-between'>
          <Button
            onClick={() => {
              setColor('#006782');
              handleClick('navy');
            }}
            overrideClass={cx(
              'fui-h-6 fui-w-6 fui-bg-navy-500 fui-rounded-full',
              clickedButton === 'navy' ? 'fui-border fui-border-white' : ''
            )}
          />
          <Button
            buttonStyle='secondary'
            overrideClass={cx(
              'fui-h-6 fui-w-6 fui-bg-salmon-500 fui-rounded-full',
              clickedButton === 'salmon' ? 'fui-border fui-border-white' : ''
            )}
            rounded='full'
            onClick={() => {
              setColor('#E9B8A5');
              handleClick('salmon');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ColorChooser;
