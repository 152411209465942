export const AddressPageSkeleton = () => {
  return (
    <div className='fui-block'>
      <div className='fui-animation-delay-75 fui-block fui-animate-pulse fui-pt-4'>
        <div className='rounded-full fui-mb-4 fui-h-8  fui-w-full fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[330px] fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[300px] fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
      </div>
      <div className='fui-animation-delay-150 fui-block fui-animate-pulse fui-pt-12'>
        <div className='rounded-full fui-mb-4 fui-h-8  fui-w-full fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[300px] fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
      </div>
      <div className='fui-animation-delay-300 fui-block fui-animate-pulse fui-pt-12'>
        <div className='rounded-full fui-mb-4 fui-h-8  fui-w-full fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-4 fui-mt-8 fui-h-8 fui-w-full fui-bg-gray-15/20'></div>
      </div>
    </div>
  );
};
