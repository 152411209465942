import React from 'react';
import cx from 'clsx';

import MediaView from './MediaView';
export const RacipientFeelingsCard: React.FC<{
  title: string;
  message: string;
  url: string;
  alt?: string;
  isVideo: boolean;
  titleClass?: string;
  msgClass?: string;
  page?: string;
}> = ({
  title,
  message,
  url,
  isVideo,
  alt = '',
  titleClass = 'fui-text-xl fui-mb-8 fui-mt-[60px] ',
  msgClass = 'fui-text-xl',
  page = '',
}) => {
  return (
    <>
      <div className={cx('fui-font-bold', titleClass)}>{title}</div>
      {url && (
        <MediaView
          url={url}
          isVideo={isVideo}
          alt={alt}
          containerClass='fui-mb-4'
          preview={true}
          page={page}
        />
      )}
      {message && (
        <div
          className={cx(
            'fui-mb-4 fui-font-medium -fui-tracking-[0.3px]',
            msgClass
          )}
        >
          {message}
        </div>
      )}
    </>
  );
};

export default RacipientFeelingsCard;
