import React, { useState } from 'react';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { useParams } from 'react-router-dom';
import { Modal } from '@flowardco/fui-modal';
import { TextareaField } from '@flowardco/fui-input-field';
import { Button } from '@flowardco/fui-button';
import { addToast } from '@flowardco/flib-util';
import StarRating from './StarRating';
import ReasonsTagList from './ReasonsTagList';
import { addRecipientRating } from '@/api/recipient.service';
import { ThankYouMessage } from './ThankYouMessage';

export type Reasons = {
  category: number;
  id: number;
  order: number;
  reasonAr: string;
  reasonEn: string;
  showTo: number;
};

interface RateExperienceModalProps {
  initialRating: number;
  onClose: () => void;
  onRatingDismiss: () => void;
  reasons: Reasons[];
  orderId: number;
}

const RateExperienceModal: React.FC<RateExperienceModalProps> = ({
  initialRating,
  onClose,
  onRatingDismiss,
  reasons,
  orderId,
}) => {
  const { __T, __TK } = useLocalTranslator();
  const params = useParams();
  const [selectedReasons, setSelectedReasons] = useState<number[]>([]);
  const [currentRating, setCurrentRating] = useState<number>(initialRating);
  const [message, setMessage] = useState<string>('');
  const [showThankyou, setShowThankyou] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const handleRating = (newRating: number) => {
    setCurrentRating(newRating);
    if (newRating > 3) {
      setSelectedReasons([]);
    }
  };

  const handleReasonClick = (reasonId: number) => {
    setSelectedReasons((selectedReasons) => {
      if (selectedReasons.includes(reasonId)) {
        return selectedReasons.filter((id) => id !== reasonId);
      } else {
        return [...selectedReasons, reasonId];
      }
    });
  };

  const handleMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value.trim());
  };

  const handleSubmit = async () => {
    const sentData = {
      reasonsIds: selectedReasons,
      otherReason: message,
      orderRating: currentRating,
      isDismissed: false,
    };
    setIsProcessing(true);
    try {
      const res = await addRecipientRating(
        orderId,
        params?.token || '',
        sentData
      );
      if (res?.message) {
        addToast(res?.message, 'success');
        setShowThankyou(true);
        onRatingDismiss();
      }
    } catch (error) {
      addToast(__T('Something went wrong'), 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      onIconClose={onClose}
      onBackdropClose={onClose}
      className='fui-w-full fui-rounded-lg fui-p-6 md:fui-w-[520px]'
      closeBtnClass='!fui-text-black-900 !fui-top-3 !fui-right-3 rtl:!fui-right-auto rtl:!fui-left-3'
    >
      {showThankyou ? (
        <>
          <ThankYouMessage
            title={__T('Thank you for your feedback!')}
            description={__T(
              `We are committed to improving our experience, your comments will help us do that.`
            )}
          />
        </>
      ) : (
        <>
          <div className='fui-flex fui-flex-col fui-items-center'>
            <h3 className='fui-block fui-pb-2 fui-font-semibold'>
              {__T('Rate your experience')}
            </h3>
            <StarRating
              maxRating={5}
              handleRating={handleRating}
              initialRating={currentRating}
              showSelectedTitle
            />
          </div>
          {currentRating <= 3 && reasons && reasons?.length > 0 ? (
            <>
              <div className='fui-block fui-pb-4'>
                <h3 className='fui-block fui-py-1 fui-text-center fui-text-2xl fui-font-bold fui-text-black-000'>
                  {__T('Sorry about that!')}
                </h3>
                <p className='fui-block fui-px-5 fui-py-1 fui-text-center fui-text-base fui-font-medium fui-text-black-100'>
                  {__T(
                    'We are serious about improving our experience. tell us what went wrong.'
                  )}
                </p>
              </div>
              <ReasonsTagList
                reasons={reasons}
                selectedReasons={selectedReasons}
                handleReasonClick={handleReasonClick}
                __TK={__TK}
              />
            </>
          ) : (
            ''
          )}
          {currentRating >= 4 ? (
            <ThankYouMessage
              title={__T(`We're glad you like it!`)}
              description={__T(
                `Let us know what you loved about it, or share any comments.`
              )}
            />
          ) : null}
          <TextareaField
            placeholder={__T('Could you tell us more?')}
            textAreaClassName='!fui-rounded-md'
            name='message'
            onChange={handleMessage}
          />
          <Button
            label={__T('Submit')}
            className='fui-mt-7 fui-w-full !fui-rounded-md !fui-p-4 disabled:!fui-border-gray-300 disabled:!fui-bg-gray-300 disabled:!fui-text-white'
            onClick={handleSubmit}
            disabled={
              (currentRating <= 3 && !selectedReasons?.length && !message) ||
              isProcessing
            }
            isProcessing={isProcessing}
          />
        </>
      )}
    </Modal>
  );
};

export default RateExperienceModal;
