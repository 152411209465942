import React from 'react';
import cx from 'clsx';

export interface SkeletonLoaderProps {
  wrapperClassName?: string;
  className?: string;
  children?: React.ReactNode;
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  wrapperClassName,
  className,
  children,
}) => {
  return (
    <div
      className={cx(
        'fui-h-full fui-w-full fui-animate-pulse',
        wrapperClassName
      )}
      data-testid='TestId__SkeletonLoader'
    >
      {children || (
        <div
          className={cx('fui-h-full fui-w-full fui-bg-gray-250', className)}
          data-testid='TestId__SkeletonLoaderContent'
        />
      )}
    </div>
  );
};
