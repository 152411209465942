import type { Dispatch, SetStateAction } from 'react';
import React, { useRef, useState, useEffect } from 'react';
import ToggleButton from '@/components/Shared/ToggleButton';
import { drawText, loadFileIntoCanvas } from '@/lib/util/canvas';
import { addToast } from '@flowardco/flib-util';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { useLocalSegment } from '@/hooks/useLocalSegment';

export interface MediaViewProps {
  url: string;
  alt?: string;
  isVideo: boolean;
  containerClass?: string;
  message?: string;
  preview?: boolean;
  setFile?: Dispatch<SetStateAction<{ message: string; file: File | null }>>;
  page: string;
}
export const MediaView: React.FC<MediaViewProps> = ({
  url,
  alt,
  isVideo,
  containerClass = '',
  message = '',
  preview = false,
  setFile,
  page,
}) => {
  const { __T } = useLocalTranslator();
  const { trackEvents } = useLocalSegment();
  const [playVideo, setPlayVideo] = useState(false);
  const [isMessageToggled, setIsMessageToggled] = useState<boolean>(false);
  const [originalCanvasURL, setOriginalCanvasURL] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const handlePlayVideo = () => {
    videoRef?.current?.setAttribute('controls', 'true');
    setPlayVideo(!playVideo);
    if (playVideo) {
      videoRef?.current?.pause();
    } else {
      videoRef?.current?.play();
    }
  };

  // Set the canvas without text
  const setOldCanvas = async (canvasUrl: string): Promise<File> => {
    const response = await fetch(canvasUrl);
    const blob = await response.blob();
    const file = new File([blob], 'floward.png', { type: blob.type });
    if (setFile) {
      setFile((prevState: any) => ({
        ...prevState,
        file,
      }));
    }
    return file;
  };

  const handleToggle = (): void => {
    if (!message) {
      addToast(__T('Please enter your message before proceeding.'));
    } else {
      setIsMessageToggled(!isMessageToggled);
      // If the message is toggled, add the text to the canvas image.
      if (!isMessageToggled) {
        trackEvents('Embedded Message', { toggle: !isMessageToggled });
        drawText(isMessageToggled, canvasRef, message, setOriginalCanvasURL);
        // Set the canvas with text to the form input for submission.
        if (canvasRef.current) {
          canvasRef.current.toBlob((blob: any) => {
            if (blob) {
              const file = new File([blob], 'floward.png', {
                type: 'image/png',
              });
              // Set the new file in form inputs
              if (setFile) {
                setFile((prevState: any) => ({
                  ...prevState,
                  file,
                }));
              }
            }
          }, 'image/png');
        }
      } else {
        // Load previous canvas without text and set as form input if message not toggled.
        if (isMessageToggled && canvasRef.current) {
          trackEvents('Embedded Message', { toggle: !isMessageToggled });
          loadFileIntoCanvas(originalCanvasURL, canvasRef, isVideo);
          setOldCanvas(originalCanvasURL);
        }
      }
    }
  };

  useEffect(() => {
    if ((page && page !== 'completed') || preview) {
      loadFileIntoCanvas(url, canvasRef, isVideo);
    }
  }, [url]);

  return (
    <div className={containerClass}>
      {isVideo ? (
        <div
          className='fui-relative fui-h-40 fui-w-full fui-rounded-lg fui-p-0 md:fui-max-h-[400px]'
          onClick={handlePlayVideo}
        >
          <video
            src={url}
            className='fui-relative fui-h-full fui-w-full fui-rounded-lg'
            ref={videoRef}
            controlsList='nodownload'
          >
            <track kind='captions' />
          </video>
          {url && (
            <img
              src='/assets/icons/user.play.png'
              alt='play-icon'
              onClick={handlePlayVideo}
              className='fui-absolute fui-left-1/2 fui-top-1/2 -fui-translate-x-1/2 -fui-translate-y-1/2 fui-transform'
              style={{ display: playVideo ? 'none' : 'block' }}
            />
          )}
        </div>
      ) : null}
      {page === 'completed' && !isVideo ? (
        <img
          src={url}
          alt={alt || 'recipient-media-feelings'}
          className='fui-max-h-40 fui-w-full fui-rounded-lg fui-object-contain fui-p-0 md:fui-max-h-[400px]'
        />
      ) : null}
      {page !== 'completed' && !isVideo ? (
        <>
          <canvas
            ref={canvasRef}
            className='fui-max-h-52 fui-w-full fui-rounded-lg fui-object-contain fui-p-0 md:fui-max-h-[400px]'
          />
          {!preview && (
            <div className='fui-my-6 fui-flex fui-justify-between'>
              <div className='fui-mr-4 fui-text-start'>
                <h4 className='fui-mb-1 fui-font-semibold fui-leading-5'>
                  {__T('Add the text to the photo')}
                </h4>
                <p className='fui-text-sm fui-leading-3'>
                  {__T(
                    'Your text will be added to the photo. the sender can save or share it.'
                  )}
                </p>
              </div>
              <ToggleButton
                isToggled={isMessageToggled}
                onToggle={handleToggle}
              />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default MediaView;
