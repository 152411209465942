import React from 'react';
import cx from 'clsx';
import type { SlotCardProps } from './SlotCard';
import { SlotCard } from './SlotCard';

export interface SlotCardListProps {
  onClick(event: React.MouseEvent, item: any): void;
  data: SlotCardProps[];
  className?: string;
  wrapperClassName?: string;
}

export const SlotCardList: React.FC<SlotCardListProps> = ({
  data,
  onClick,
  className,
  wrapperClassName,
}) => {
  return (
    <div
      className={cx('fui-block', wrapperClassName)}
      data-testid='TestId__SlotCardListWrapper'
    >
      <div
        className={cx(
          'fui-no-scrollbar fui-flex fui-max-w-none fui-flex-1 fui-flex-nowrap fui-justify-start fui-overflow-auto ltr:fui-pr-4 rtl:fui-pl-4',
          className
        )}
        data-testid='TestId__SlotCardList'
      >
        {data.map((item, index) => (
          <SlotCard
            key={`SlotCard_${index}`}
            {...item}
            onClick={(e) => onClick(e, item)}
            className={cx(item?.className, 'ltr:fui-mr-2 rtl:fui-ml-2')}
            testId={`TestId__SlotCard_${index}`}
          />
        ))}
      </div>
    </div>
  );
};
