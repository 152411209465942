import type { FormEvent } from 'react';
import { useState } from 'react';
import { Button } from '@flowardco/fui-button';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import {
  Navigate,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { postRecipientMessageData } from '@/api/recipient.service';
import { addToast } from '@flowardco/flib-util';
import { UploadFeelings } from '@/components/RecipientMessage/UploadFeelings';
import PreviewModal from '@/components/RecipientMessage/PreviewModal';
import RateExperience from '@/components/Shared/RateExperience/RateExperience';
import { PageLayout } from '@/components/PageLayout';
import FramesModal from './Frames/FramesModal';

type formInputsType = {
  message: string;
  file: File | null;
};

const RecipientMessage = () => {
  const { token = '' } = useParams();
  const messageInfo: any = useLoaderData();
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState<formInputsType>({
    message: '',
    file: null,
  });
  const [mediaType, setMediaType] = useState<number | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [mediaValidationMsg, setMediaValidationMsg] = useState(false);
  const { __T } = useLocalTranslator();
  const [showFramesModal, setShowFramesModal] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  const handleFileUpload = (e: any) => {
    if (e?.target?.files[0]?.size > 20971520) {
      // 20 MB in bytes
      setMediaValidationMsg(true);
      return;
    }
    setMediaValidationMsg(false);
    const type = e?.target?.files[0]?.type;
    const imageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg'];
    if (imageTypes.includes(type)) {
      setMediaType(0);
      setShowFramesModal(true);
    } else {
      setMediaType(1);
    }
    if (e.target.files.length) {
      setFormInputs({
        ...formInputs,
        file: e?.target?.files[0],
      });
    }
  };
  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    // handle form submit action to save image and message to api
    // handle file upload and later handling frames
    let mediaData = {};
    if (formInputs?.file) {
      mediaData = { mediaType, media: formInputs.file };
    }
    const sentData = {
      message: formInputs.message,
      ...mediaData,
    };
    const formData = new FormData();
    for (const key in sentData) {
      formData.append(key, sentData[key]);
    }
    try {
      setDisableBtn(true);
      const res = await postRecipientMessageData(token, formData);
      if (res?.success) {
        addToast(res?.message || 'Success');
        navigate('done');
      }
      setDisableBtn(false);
    } catch (error) {
      setDisableBtn(false);
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  };
  // If message already sent, then redirect to thank you page
  // path is relative to current path
  if (messageInfo?.recipientFeelings?.hasRecipientSharedFeeling) {
    return <Navigate to={'done'} />;
  }

  return (
    <PageLayout>
      {showFramesModal && (
        <FramesModal
          onClose={() => {
            setShowFramesModal(false);
          }}
          fileURL={formInputs.file ? URL?.createObjectURL(formInputs.file) : ''}
          setFile={setFormInputs}
          isVideo={mediaType === 1}
        />
      )}
      {showPreview && (
        <PreviewModal
          previewDetails={messageInfo?.recipientFeelings}
          onClose={() => setShowPreview(false)}
          mediaType={mediaType}
          url={formInputs.file ? URL?.createObjectURL(formInputs.file) : ''}
          message={formInputs.message}
        />
      )}
      <div className='fui-block fui-pb-20'>
        <h2 className='fui-block fui-px-2 fui-py-4 fui-text-center fui-text-2xl fui-font-bold'>
          {__T('Tell {sendername} how much you love the gift! 😍', {
            sendername: messageInfo?.senderFeelings?.isSenderSharingDetails
              ? messageInfo?.senderFeelings?.senderName
              : __T('the Sender'),
          })}
        </h2>
        <div className='fui-py-4'>
          <UploadFeelings
            formInputs={formInputs}
            mediaType={mediaType}
            setFormInputs={setFormInputs}
            handleFileUpload={handleFileUpload}
            handleFormSubmit={handleFormSubmit}
            __T={__T}
            mediaValidationMsg={mediaValidationMsg}
            setFile={setFormInputs}
          />
        </div>
        <RateExperience data={messageInfo} />

        <div className='fui-fixed fui-bottom-0 fui-left-0 fui-right-0 fui-top-auto fui-z-10 fui-mx-auto fui-flex fui-items-center fui-justify-between fui-bg-white fui-px-3 fui-py-4 fui-align-middle fui-shadow-up md:fui-max-w-[700px]'>
          <div className='fui-w-1/2 fui-p-1'>
            <Button
              className='fui-w-full'
              type='button'
              label={__T('Preview')}
              buttonStyle='outlinePrimary'
              onClick={() => setShowPreview(true)}
              disabled={!formInputs?.message && !formInputs?.file}
            />
          </div>
          <div className='fui-w-1/2 fui-p-1'>
            <Button
              className='fui-w-full'
              type='submit'
              label={__T('Send')}
              form='recipientMessageForm'
              disabled={
                (!formInputs?.message && !formInputs?.file) || disableBtn
              }
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default RecipientMessage;
