import { useContext } from 'react';
import {
  http,
  DEFAULT_CURRENCY,
  cookieStore,
  COOKIE_KEYS,
} from '@flowardco/flib-helpers';
import { AppContext } from '../context';
import type { AxiosInstance } from 'axios';

export const useFetch = (baseURL = '/'): AxiosInstance => {
  const app = useContext(AppContext);
  const { appState } = app;
  const client = http({
    baseURL,
    headers: {
      lang: appState?.lang === 'en' ? 1 : 0,
      OpsCountryID: appState?.opsCountryId || 1,
      opsId: appState?.opsId || 1,
      currency: appState?.currencyIso || DEFAULT_CURRENCY,
      segment_anonymous_id:
        appState?.segmentUserId ||
        cookieStore.getCookie(COOKIE_KEYS.SEGMENT_SDK_ANNONYMOUS_ID) ||
        '',
    },
  });
  return client;
};
