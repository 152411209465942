export const formatOrderStepsResponse = (
  data: any,
  __TK: any
): { img: string; title: string }[] => {
  return (
    data?.map((step: any) => ({
      img: step.img,
      title: __TK(step?.title, step?.titleAr),
      status: step?.finished === true ? 'completed' : '',
    })) || []
  );
};

export const getNumberOfDaysFromDate = (
  todayDate: string,
  selectedDate: string
) => {
  const todayDateArray: string[] = todayDate.split('-');
  const selectedDateArray: string[] = selectedDate.split('-');
  const daysCount: number =
    parseInt(selectedDateArray[2], 10) - parseInt(todayDateArray[2], 10);
  return daysCount + ' days plus';
};
