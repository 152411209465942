import React from 'react';
import cx from 'clsx';

export interface ContainerProps {
  className?: string;
  overrideClass?: string;
  children?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({
  children = null,
  className = '',
  overrideClass = null,
}) => {
  const containerClass =
    overrideClass ?? cx('fui-mx-auto md:fui-container', className);
  return (
    <div className={containerClass} data-testid='TestId__Container'>
      {children}
    </div>
  );
};
