import { useSegment } from '@flowardco/app-shared/hooks/useSegment';
import { useParams } from 'react-router';

export const useLocalSegment = () => {
  const { isSegmentEnabled, isSegmentLoaded } = useSegment();
  const { lang } = useParams();
  const trackEvents = (
    eventName: string,
    trackingData?: any,
    opsId?: string | number
  ): void => {
    if (isSegmentEnabled() && isSegmentLoaded()) {
      window['analytics'].track(eventName, {
        ops_id: opsId || 1,
        language: lang,
        store_channel: 'Web',
        ...(trackingData || {}),
      });
    }
  };
  // Fire on dropping pin on unavailable area in address page -checkout
  const trackUnavailableZones = (
    eventName: string,
    trackingData: any
  ): void => {
    if (isSegmentLoaded()) {
      trackEvents(eventName, {
        error: 'User dropped a pin outside the available zones',
        ...trackingData,
      });
    }
  };
  return { trackEvents, trackUnavailableZones };
};
