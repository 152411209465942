import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconTruck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 20 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M19.5 11.5H19V7.875c0-.594-.25-1.156-.688-1.594L15.72 3.688c-.438-.438-1-.688-1.594-.688H13V1.75C13 .812 12.187 0 11.25 0h-9.5C.781 0 0 .813 0 1.75v9.5C0 12.219.781 13 1.75 13H2a3 3 0 0 0 6 0h4a3 3 0 0 0 6 0h1.5c.25 0 .5-.219.5-.5V12c0-.25-.25-.5-.5-.5ZM5 14.5A1.48 1.48 0 0 1 3.5 13 1.5 1.5 0 0 1 5 11.5c.813 0 1.5.688 1.5 1.5A1.5 1.5 0 0 1 5 14.5Zm6.5-3H7.562C7.063 10.625 6.095 10 5 10a2.995 2.995 0 0 0-2.594 1.5H1.75c-.156 0-.25-.094-.25-.25v-9.5c0-.125.094-.25.25-.25h9.5c.125 0 .25.125.25.25v9.75Zm1.5-7h1.125c.188 0 .375.094.531.219L16.937 7H13V4.5Zm2 10a1.48 1.48 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5c.813 0 1.5.688 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5Zm2.5-3.125C16.937 10.562 16.031 10 15 10c-.781 0-1.469.313-2 .781V8.5h4.5v2.875Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconTruck);
export default ForwardRef;
