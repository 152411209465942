import React from 'react';
import cx from 'clsx';

interface ToggleButtonProps {
  isToggled: boolean;
  onToggle: () => void;
}

const ToggleButton = ({ isToggled, onToggle }: ToggleButtonProps) => {
  return (
    <label
      className={cx(
        'fui-flex fui-h-[31px] fui-w-[51px] fui-shrink-0 fui-cursor-pointer fui-items-center fui-justify-start fui-rounded-full fui-bg-gray-320',
        isToggled ? 'fui-justify-end fui-bg-navy-500' : ''
      )}
    >
      <input
        type='checkbox'
        className='fui-hidden'
        checked={isToggled}
        onChange={onToggle}
      />
      <span
        className={cx(
          'fui-m-1 fui-h-[27px] fui-w-[27px] fui-rounded-full fui-bg-white fui-drop-shadow-md'
        )}
      ></span>
    </label>
  );
};

export default ToggleButton;
