/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';
import { Button } from '@flowardco/fui-button';
import IconSocialYoutube from '@flowardco/fui-icons/src/IconSocialYoutube';
import { addToast } from '@flowardco/flib-util';

interface VideoPlayerProps {
  link?: string;
  __T: any;
}
const VideoPlayer: React.FC<VideoPlayerProps> = ({ link, __T }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState<boolean>(true);

  const togglePlay = () => {
    if (videoRef.current?.paused || videoRef.current?.ended) {
      videoRef.current?.play();
      setIsPaused(false);
    } else {
      videoRef.current?.pause();
      setIsPaused(true);
    }
  };

  const handleError = () => {
    addToast(__T('There was a problem playing your video'), 'error');
  };

  return (
    <>
      <div className='fui-relative fui-h-full fui-w-full'>
        <video
          src={link}
          controls={!isPaused}
          autoPlay={true}
          muted
          className='fui-mx-auto fui-h-full fui-w-full fui-object-contain'
          playsInline
          ref={videoRef}
          onPause={() => setIsPaused(true)}
          onPlay={() => {
            setIsPaused(false);
          }}
          onError={handleError}
        >
          <p> {__T('There was a problem playing your video')}</p>
        </video>
        {isPaused ? (
          <Button
            buttonStyle='ternary'
            rounded='full'
            overrideClass={
              '!fui-absolute fui-left-1/2 fui-top-1/2 fui-h-[147.5px] fui-w-[68px] fui--translate-x-1/2 fui--translate-y-1/2 fui-transform fui-opacity-50 fui-h-full'
            }
            onClick={togglePlay}
          >
            <IconSocialYoutube />
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default VideoPlayer;
