import { Header } from '@/components/Header';
import { useParams } from 'react-router-dom';

export interface PageLayoutProps {
  showCustomerSupport?: boolean;
  onCustomerSupportClick?: () => void;
  showLanguageSwitch?: boolean;
  children: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  showCustomerSupport = false,
  showLanguageSwitch = true,
  onCustomerSupportClick = () => null,
  children,
}) => {
  const { lang = 'en' } = useParams();
  const language = lang.toLowerCase() === 'ar' ? 'ar' : 'en';
  return (
    <div>
      <Header
        language={language}
        showCustomerSupport={showCustomerSupport}
        showLanguageSwitch={showLanguageSwitch}
        onCustomerSupportClick={onCustomerSupportClick}
      />
      <main>{children}</main>
    </div>
  );
};
